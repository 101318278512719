import { forwardRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import {
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";

// project import
import { activeItem } from "redux/slices/MenuSlice";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

const NavItem = ({ item, level }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const menu = useSelector((state) => state.menu);
    const { drawerOpen, openItem } = menu;

    let itemTarget = "_self";
    if (item.target) {
        itemTarget = "_blank";
    }

    let listItemProps = {
        component: forwardRef((props, ref) => (
            <Link ref={ref} {...props} to={item.url} target={itemTarget} />
        )),
    };
    if (item?.external) {
        listItemProps = { component: "a", href: item.url, target: itemTarget };
    }

    const itemHandler = (id) => {
        dispatch(activeItem({ openItem: [id] }));
    };

    const itemIcon = item.icon ? (
        <FontAwesomeIcon
            icon={item.icon}
            style={{ fontSize: drawerOpen ? "1rem" : "1.25rem" }}
        />
    ) : (
        false
    );

    const isSelected = openItem.findIndex((id) => id === item.id) > -1;

    // active menu item on page load2
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split("/")
            .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
            dispatch(activeItem({ openItem: [item.id] }));
        }
        // eslint-disable-next-line
    }, []);

    const textColor = "text.light";
    const iconSelectedColor = "text.lighter";

    return (
        <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            onClick={() => itemHandler(item.id)}
            selected={isSelected}
            sx={{
                zIndex: 1201,
                pl: drawerOpen ? `${level * 28}px` : 1.5,
                py: !drawerOpen && level === 1 ? 1.25 : 1,
                ...(drawerOpen && {
                    "&:hover": {
                        bgcolor: "#0b3970",
                    },
                    "&.Mui-selected": {
                        bgcolor: "#0b3970",
                        color: iconSelectedColor,
                        "&:hover": {
                            color: iconSelectedColor,
                            bgcolor: "#0b3970",
                        },
                    },
                }),
                ...(!drawerOpen && {
                    "&:hover": {
                        bgcolor: "transparent",
                    },
                    "&.Mui-selected": {
                        "&:hover": {
                            bgcolor: "transparent",
                        },
                        bgcolor: "transparent",
                    },
                }),
            }}
        >
            {itemIcon && (
                <ListItemIcon
                    sx={{
                        minWidth: 28,
                        color: isSelected ? iconSelectedColor : textColor,
                        ...(!drawerOpen && {
                            borderRadius: 1.5,
                            width: 36,
                            height: 36,
                            alignItems: "center",
                            justifyContent: "center",
                            "&:hover": {
                                bgcolor: "#0b3970",
                            },
                        }),
                        ...(!drawerOpen &&
                            isSelected && {
                                bgcolor: "#0b3970",
                                "&:hover": {
                                    bgcolor: "#0b3970",
                                },
                            }),
                    }}
                >
                    {itemIcon}
                </ListItemIcon>
            )}
            {(drawerOpen || (!drawerOpen && level !== 1)) && (
                <ListItemText
                    primary={
                        <Typography
                            variant="h6"
                            sx={{
                                color: isSelected
                                    ? iconSelectedColor
                                    : textColor,
                            }}
                        >
                            {t(item.title)}
                        </Typography>
                    }
                />
            )}
        </ListItemButton>
    );
};

export default NavItem;
