import React, { useEffect } from "react";
import { useStore } from "react-redux";
import { CONVERSATIONS } from "helpers/accessModule";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { MainCard } from "components";
import ConversationsList from "./ConversationsList";
import { LIST_CONVERSATIONS } from "helpers/accessSubmodule";

const Conversations = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const store = useStore();

    const { module, submodules } = store.getState().auth.access;

    useEffect(() => {
        if (!module.includes(CONVERSATIONS)) {
            navigate("/403");
        }
    }, []);

    return (
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            {submodules.includes(LIST_CONVERSATIONS) && (
                <Grid item xs={12} md={12} lg={12}>
                    <MainCard title={t("listedesconversations")}>
                        <ConversationsList />
                    </MainCard>
                </Grid>
            )}
        </Grid>
    );
};

export default Conversations;
