import {Box, Grid} from '@mui/material';
import {AuthCard, LanguageSelector} from '../components';

const AuthLayout = ({children}) => (
    <Box sx={{minHeight: '100vh'}}>

        <Grid
            container
            direction="column"
            justifyContent="flex-end"
            sx={{
                minHeight: '60vh'
            }}
        >
            <Grid item xs={12}>

                <Grid
                    item
                    xs={12}
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{minHeight: {xs: 'calc(95vh - 134px)', md: 'calc(95vh - 112px)'}}}
                >
                    <Grid item container justifyContent="center" alignItems="center" xs={12}>
                        <Box
                            component="img"
                            sx={{
                                maxHeight: "200px"
                            }}
                            alt="Glow in the cloud"
                            src="/img/logo/glow-blue-en.png"
                        />
                    </Grid>

                    <Grid item>
                        <AuthCard>{children}</AuthCard>
                    </Grid>

                    <Grid item container justifyContent="center" alignItems="center" xs={12}>
                        <LanguageSelector/>
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    </Box>
);

export default AuthLayout;