import React, {useEffect, useMemo, useState} from 'react';
import {toast} from "react-toastify";
import DataTable from 'react-data-table-component';
import { Loader } from "components";
import CandidateApi from "services/Api/CandidateApi";
import FilterHelper from "helpers/filter";
import Filter from "_tools/Datatable/Filter";
import {useTranslation} from "react-i18next";
import CurrentMandatesListActions from "./CurrentMandatesListActions";

const CurrentMandatesList = () => {

    const { t } = useTranslation();

    const filterHelper = new FilterHelper();
    const candidateApi = new CandidateApi();

    const [loading, setLoading] = useState(true);
    const [currentMandates, setCurrentMandates] = useState([]);
    const [filterText, setFilterText] = useState('');

    const getCurrentMandates = async () => {
        const response = await candidateApi.getCurrentMandates();
        if (response.success) {
            setCurrentMandates(response.currentMandates);
            setLoading(false);
        } else {
            toast.error(t(response.message));
        }
    }

    const columns = [
        {
            name: t('mandat'),
            sortable: true,
            wrap: true,
            selector: 'mandatId'
        },
        {
            name: t('client'),
            sortable: true,
            wrap: true,
            selector: 'clientName'
        },
        {
            name: t('gestionnaire'),
            sortable: true,
            wrap: true,
            selector: 'managerName'
        },
        {
            name: t('datedebut'),
            sortable: true,
            wrap: true,
            selector: 'start'
        },
        {
            name: t('datefin'),
            sortable: true,
            wrap: true,
            selector: 'end'
        },
        {
            name: t(''),
            sortable: true,
            wrap: true,
            selector: 'end',
            cell: (row) => <CurrentMandatesListActions row={row} callBack={getCurrentMandates}/>,

        },
    ];

    const subHeaderFilterComponent = useMemo(() => {
        return <Filter onFilter={e => setFilterText(e.target.value)} filterText={filterText}/>;
    }, [filterText]);

    const dataTableConverter = (data) => {
        let dataTable = [];
        if (data) {
            data.map((value, index) => {
                let timestamp = new Date().getUTCMilliseconds();
                dataTable.push({
                    key: index + "_" + timestamp,
                    index: index,
                    conversationId: value.employeeConversationId,
                    mandatId: value.id,
                    clientName: value.client.name,
                    managerName: value.manager.name,
                    managerEmail: value.manager.email,
                    start: value.start,
                    end: value.end,
                    messagesNoRead: value.messagesNoRead,
                })
            })
        }
        return dataTable;
    }

    useEffect(() => {
        getCurrentMandates();
    }, [])

    if(currentMandates && !loading) {
        return (
            <DataTable
                columns={columns}
                noDataComponent={t("aucunemploistrouves")}
                data={filterHelper.filteredItems(dataTableConverter(currentMandates), filterText)}
                keyField="key"
                pagination
                noHeader
                subHeader
                highlightOnHover
                subHeaderComponent={subHeaderFilterComponent}
                paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                paginationComponentOptions={{
                    rowsPerPageText: t('lignesparpage'),
                    rangeSeparatorText: t('sur'),
                    noRowsPerPage: false,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: 'All'
                }}
            />
        )
    }else{
        return (
            <Loader/>
        );
    }

}

export default CurrentMandatesList;
