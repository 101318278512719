import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/pro-solid-svg-icons";

const Filter = ({filterText, onFilter}) => {
    return (
        <div className="d-flex justify-content-end">
            <div className="input-group">
                <input className="form-control py-2 border-right-0 border" type="search" value={filterText} onChange={onFilter}/>
                <span className="input-group-text"><FontAwesomeIcon icon={faSearch}/></span>
            </div>
        </div>
    )
};

export default Filter;