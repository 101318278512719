import Axios from "./AxioConfig";

class DocumentApi {

    uploadFile(documents, type, progressCallBack) {

        const config = {
            onUploadProgress: progress => {
                progressCallBack(progress, documents[0].name)
            }
        }
        return Axios.post(`/api/document/file/upload?type=${type}`, JSON.stringify(documents), config).then((response => {
            return response.data;
        }));
    }
}

export default DocumentApi