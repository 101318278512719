import React, {useEffect, useMemo, useState} from 'react';
import FilterHelper from "../../helpers/filter";
import CandidateApi from "../../services/Api/CandidateApi";
import {toast} from "react-toastify";
import Filter from "../../_tools/Datatable/Filter";
import DataTable from "react-data-table-component";
import moment from "moment";
import EvaluationsListActions from "./EvaluationListActions";
import {useTranslation} from "react-i18next";
import { Loader } from "../../components";

const EvaluationsList = () => {
    const {t} = useTranslation();

    const filterHelper = new FilterHelper();
    const candidateApi = new CandidateApi();

    const [loading, setLoading] = useState(true);
    const [evaluations, setEvaluations] = useState([]);
    const [filterText, setFilterText] = useState('');

    const getEvaluations = async () => {
        const response = await candidateApi.getEvaluations();
        if (response.success) {
            setEvaluations(response.evaluations);
            setLoading(false);
        } else {
            toast.error(t(response.message));
        }
    }

    const columns = [
        {
            name: t('title'),
            sortable: true,
            wrap: true,
            selector: 'title'
        },
        {
            name: t('datedebut'),
            sortable: true,
            wrap: true,
            selector: 'start'
        },
        {
            name: t('datefin'),
            sortable: true,
            wrap: true,
            selector: 'end'
        },
        {
            name: t('statut'),
            sortable: true,
            wrap: true,
            selector: 'status'
        },
        {
            name: t('actions'),
            sortable: true,
            wrap: true,
            cell: (row) => <EvaluationsListActions row={row} />
        },
    ];

    const subHeaderFilterComponent = useMemo(() => {
        return <Filter onFilter={e => setFilterText(e.target.value)} filterText={filterText}/>;
    }, [filterText]);

    const dataTableConverter = (data) => {
        let dataTable = [];
        if (data) {
            data.map((value, index) => {
                let timestamp = new Date().getUTCMilliseconds();
                dataTable.push({
                    key: index + "_" + timestamp,
                    index: index,
                    title: value.title,
                    start: moment(value.start).format("L"),
                    end: moment(value.end).format("L"),
                    status: value.isCompleted ? t('complete') : t('encours'),
                    comments: value.comments,
                    goals: value.goals,
                    skills: value.skills,
                    performances: value.performances,
                })
            })
        }
        return dataTable;
    }

    useEffect(() => {
        getEvaluations();
    }, [])

    if(evaluations && !loading) {
        return (
            <DataTable
                columns={columns}
                noDataComponent={t("aucuneevaluationstrouvees")}
                data={filterHelper.filteredItems(dataTableConverter(evaluations), filterText)}
                keyField="key"
                pagination
                noHeader
                subHeader
                highlightOnHover
                subHeaderComponent={subHeaderFilterComponent}
                paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                paginationComponentOptions={{
                    rowsPerPageText: t('lignesparpage'),
                    rangeSeparatorText: t('sur'),
                    noRowsPerPage: false,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: 'All'
                }}
            />
        )
    }else{
        return (
            <Loader/>
        );
    }
    
}

export default EvaluationsList;
