// project import
import Navigation from "./Navigation";
import SimpleBar from "components/SimpleBar";
import GlowVersion from "./GlowVersion";

// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => (
    <>
        <SimpleBar
            sx={{
                "& .simplebar-content": {
                    display: "flex",
                    flexDirection: "column",
                },
            }}
        >
            <Navigation />
        </SimpleBar>
        <GlowVersion />
    </>
);

export default DrawerContent;
