import React, {Component, useEffect, useMemo, useState} from 'react';
import DataTable from "react-data-table-component";
import parse from "html-react-parser";
import moment from "moment";
import {useTranslation} from "react-i18next";

const SkillsEvaluations = ({data}) => {
    const {t} = useTranslation();

    const [skills, setSkills] = useState(data);

    const columns = [
        {
            name: t('competence'),
            sortable: true,
            wrap: true,
            selector: 'skill'
        },
        {
            name: t('niveauactuel'),
            sortable: true,
            wrap: true,
            selector: 'current'
        },
        {
            name: t('endatedu'),
            sortable: true,
            wrap: true,
            selector: 'start'
        },
        {
            name: t('niveauaatteindre'),
            sortable: true,
            wrap: true,
            selector: 'target'
        },
        {
            name: t('dateecheance'),
            sortable: true,
            wrap: true,
            selector: 'end'
        },
        {
            name: t('atteint'),
            sortable: true,
            wrap: true,
            selector: 'isCompleted'
        },
    ];

    const dataTableConverter = (data) => {
        let dataTable = [];
        if (data) {
            data.map((value, index) => {
                let timestamp = new Date().getUTCMilliseconds();
                dataTable.push({
                    key: index + "_" + timestamp,
                    index: index,
                    skill: parse(value.skill),
                    start: moment(value.start).format("L"),
                    end: moment(value.end).format("L"),
                    isCompleted: value.isCompleted ? t('oui') : t('non'),
                    current: value.current,
                    target: value.target,
                })
            })
        }
        return dataTable;
    }

    return (
        <DataTable
            columns={columns}
            noDataComponent={t("aucunecompetencestrouvees")}
            data={dataTableConverter(skills)}
            keyField="key"
            noHeader
            highlightOnHover
        />
    )
}

export default SkillsEvaluations;
