import React, {Component, useEffect, useMemo, useState} from 'react';
import DataTable from "react-data-table-component";
import parse from "html-react-parser";
import {useTranslation} from "react-i18next";

const GoalsEvaluations = ({data}) => {
    const {t} = useTranslation();

    const [goals, setGoals] = useState(data);

    const columns = [
        {
            name: t('description'),
            sortable: true,
            wrap: true,
            selector: 'description'
        },
        {
            name: t('atteint'),
            sortable: true,
            wrap: true,
            selector: 'isCompleted'
        },
    ];

    const dataTableConverter = (data) => {
        let dataTable = [];
        if (data) {
            data.map((value, index) => {
                let timestamp = new Date().getUTCMilliseconds();
                dataTable.push({
                    key: index + "_" + timestamp,
                    index: index,
                    description: parse(value.description),
                    isCompleted: value.isCompleted ? t('oui') : t('non'),
                })
            })
        }
        return dataTable;
    }

    return (
        <DataTable
            columns={columns}
            noDataComponent={t("aucunobjectifstrouvees")}
            data={dataTableConverter(goals)}
            keyField="key"
            noHeader
            highlightOnHover
        />
    )
}

export default GoalsEvaluations;
