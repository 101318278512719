import React, { useEffect, useState } from "react";
import { useStore } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { JOB } from "helpers/accessModule";
import { LIST_JOBS } from "helpers/accessSubmodule";
import { MainCard } from "components";
import CurrentMandatesList from "./CurrentMandates/CurrentMandatesList";
import PastMandatesList from "./PastMandatesList";


const Jobs = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const store = useStore();

    const { module, submodules } = store.getState().auth.access;

    const [value, setValue] = useState("1");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (!module.includes(JOB)) {
            navigate("/403");
        }
    }, []);

    return (
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            {submodules.includes(LIST_JOBS) && (
                <Grid item xs={12} md={12} lg={12}>
                    <MainCard title={t("listesdesemplois")}>
                        <Box sx={{ width: "100%", typography: "body1" }}>
                            <TabContext value={value}>
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: "divider",
                                    }}
                                >
                                    <TabList
                                        onChange={handleChange}
                                        aria-label={t("listesdesemplois")}
                                    >
                                        <Tab
                                            label={t("emploisencours")}
                                            value="1"
                                        />
                                        <Tab
                                            label={t("emploistermines")}
                                            value="2"
                                        />
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <CurrentMandatesList />
                                </TabPanel>
                                <TabPanel value="2">
                                    <PastMandatesList />
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </MainCard>
                </Grid>
            )}
        </Grid>
    );
};

export default Jobs;
