import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, InputLabel, Stack, TextField, styled } from "@mui/material";
import "./ProfileForm.scss";
import parse from "html-react-parser";
import GooglePlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-google-places-autocomplete";

const ProfileAddressForm = ({
    address,
    setAddress,
    googleAddress,
    setGoogleAdress,
}) => {
    const { t } = useTranslation();

    const handleAddressChange = (event) => {
        const addressUpdate = { ...address };
        addressUpdate[event.currentTarget.id] = event.currentTarget.value;
        setAddress(addressUpdate);
    };

    const handleSelect = (selectedAddress) => {
    
        geocodeByAddress(selectedAddress.label)
            .then((results) => {
                const addressDataUpdated = { ...address };
                addressDataUpdated.name = results[0].formatted_address;
                addressDataUpdated.id = results[0].place_id;

                results[0].address_components.map((value) => {
                    if (value.types.includes("street_number")) {
                        addressDataUpdated.streetNumber = value.short_name;
                    }
                    if (value.types.includes("route")) {
                        addressDataUpdated.street = value.short_name;
                    }
                    if (value.types.includes("locality")) {
                        addressDataUpdated.city = value.short_name;
                    }
                    if (value.types.includes("administrative_area_level_1")) {
                        addressDataUpdated.region = value.long_name;
                    }
                    if (value.types.includes("country")) {
                        addressDataUpdated.country = value.long_name;
                    }
                    if (value.types.includes("postal_code")) {
                        addressDataUpdated.postalCode = value.short_name;
                    }
                });

                getLatLng(results[0]).then((latLng) => {
                    addressDataUpdated.lat = latLng.lat;
                    addressDataUpdated.lng = latLng.lng;
                    setAddress(addressDataUpdated);
                });
                setGoogleAdress(addressDataUpdated.name);
            })
            .catch((error) => console.error("Error", error));
    };

    return (
        <Grid item xs={12}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Stack spacing={1}>
                        <StyledInputLabel htmlFor="address">
                            {t("adresse")}
                        </StyledInputLabel>
                        <GooglePlacesAutocomplete
                            selectProps={{
                                defaultInputValue: googleAddress,
                                onChange: handleSelect,
                                placeholder: t("saisissezuneadresse")
                            }}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack spacing={1}>
                        <StyledInputLabel htmlFor="appartment">
                            {t("appartement")}
                        </StyledInputLabel>
                        <StyledTextField
                            id="appartment"
                            type="text"
                            value={address.appartment}
                            name="appartment"
                            onChange={handleAddressChange}
                            fullWidth
                        />
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack spacing={1}>
                        <StyledInputLabel htmlFor="streetNumber">
                            {t("numero")}
                        </StyledInputLabel>
                        <StyledTextField
                            id="streetNumber"
                            type="text"
                            value={address.streetNumber}
                            name="streetNumber"
                            onChange={handleAddressChange}
                            fullWidth
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack spacing={1}>
                        <StyledInputLabel htmlFor="street">
                            {t("rue")}
                        </StyledInputLabel>
                        <StyledTextField
                            id="street"
                            type="text"
                            value={address.street ? parse(address.street) : ""}
                            name="street"
                            onChange={handleAddressChange}
                            fullWidth
                        />
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack spacing={1}>
                        <StyledInputLabel htmlFor="city">
                            {t("ville")}
                        </StyledInputLabel>
                        <StyledTextField
                            id="city"
                            type="text"
                            value={address.city ? parse(address.city) : ""}
                            name="city"
                            onChange={handleAddressChange}
                            fullWidth
                        />
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack spacing={1}>
                        <StyledInputLabel htmlFor="region">
                            {t("region")}
                        </StyledInputLabel>
                        <StyledTextField
                            id="region"
                            type="text"
                            value={address.region ? parse(address.region) : ""}
                            name="region"
                            onChange={handleAddressChange}
                            fullWidth
                        />
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack spacing={1}>
                        <StyledInputLabel htmlFor="postalCode">
                            {t("codepostal")}
                        </StyledInputLabel>
                        <StyledTextField
                            id="postalCode"
                            type="text"
                            value={address.postalCode}
                            name="codepostal"
                            onChange={handleAddressChange}
                            fullWidth
                        />
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack spacing={1}>
                        <StyledInputLabel htmlFor="country">
                            {t("pays")}
                        </StyledInputLabel>
                        <StyledTextField
                            id="country"
                            type="text"
                            value={
                                address.country ? parse(address.country) : ""
                            }
                            name="country"
                            onChange={handleAddressChange}
                            fullWidth
                        />
                    </Stack>
                </Grid>
                <input
                    type="hidden"
                    id="lat"
                    value={address.lat}
                    onChange={handleAddressChange}
                />
                <input
                    type="hidden"
                    id="lng"
                    value={address.lng}
                    onChange={handleAddressChange}
                />
                <input
                    type="hidden"
                    id="id"
                    value={address.id}
                    onChange={handleAddressChange}
                />
            </Grid>
        </Grid>
    );
};

const StyledTextField = styled(TextField)(({ theme }) => ({
    ".MuiInputBase-root": {
        height: 40,
    },
    ".MuiInputBase-input": {
        height: 5,
    },
    ".MuiInputBase-input": {
        fontSize: "14px",
    },
}));

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
    fontSize: "0.9rem",
}));

export default ProfileAddressForm;
