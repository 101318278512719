import React from "react";
import { faEye } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { ItemIconButton } from "components";
import InternalJobDetailsModal from "./InternalJobDetailsModal";
import NiceModal from "@ebay/nice-modal-react";

const InternalJobListActions = ({ row }) => {
    const { t } = useTranslation();
    return (
        <>
            <ItemIconButton
                title={t("details")}
                bgcolor={"primary.main"}
                onClick={() =>
                    NiceModal.show(InternalJobDetailsModal, {
                        data: row
                    })
                }
                icon={faEye}
            />
        </>
    );
};

export default InternalJobListActions;
