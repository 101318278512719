import React from "react";
import { faEye, faFileDownload } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { ItemIconButton } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Tooltip, Box, Link } from "@mui/material";
import FileViewerModal from "_tools/FileViewer/FileViewerModal";
import NiceModal from "@ebay/nice-modal-react";

const RowActions = ({ row }) => {
    const { t } = useTranslation();

    return (
        <>
            <ItemIconButton
                iconSize="md"
                size="medium"
                title={t("voir")}
                bgcolor={"success.main"}
                onClick={() =>
                    NiceModal.show(FileViewerModal, {
                        data: row,
                    })
                }
                icon={faEye}
            />

            <Box m={1}>
                <Tooltip title={t("telecharger")} placement="top" arrow>
                    <Link
                        href={row.downloadUrl}
                        download
                        style={{ textDecoration: "none" }}
                    >
                        <IconButton
                            disableRipple
                            color="secondary"
                            sx={{
                                color: "text.primary",
                                bgcolor: "grey.100",
                                borderRadius: "5px"
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faFileDownload}
                            />
                        </IconButton>
                    </Link>
                </Tooltip>
            </Box>
        </>
    );
};

export default RowActions;
