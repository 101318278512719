import React from "react";

const TextareaField = ({ index, field, handleChange, readOnlyForm }) => {

    /**
     * Render the textarea
     */
    return (
        <div className="p-2">
            <label htmlFor={field.id} className={`form-label fw-bolder m-2 ${field.required ? "required" : ""}`}>{field.label}</label>
            <textarea
                className="form-control"
                id={field.id}
                name={field.id}
                defaultValue={field.value}
                onChange={e => handleChange(index, e)}
            />
        </div>
    );
};

export default TextareaField;