import React, {useEffect, useMemo, useState} from 'react';
import CandidateApi from "../../services/Api/CandidateApi";
import {toast} from "react-toastify";
import DataTable from "react-data-table-component";
import Filter from "../../_tools/Datatable/Filter";
import FilterHelper from "../../helpers/filter";
import {useTranslation} from "react-i18next";
import { Loader } from "../../components";
import FormationsListActions from "./FormationsListActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faXmarkCircle } from "@fortawesome/pro-light-svg-icons";

const List = () => {

    const { t } = useTranslation();

    const candidateApi = new CandidateApi();
    const filterHelper = new FilterHelper();

    const [loading, setLoading] = useState(true);
    const [formations, setFormations] = useState([]);
    const [filterText, setFilterText] = useState('');

    const getFormations = async () => {
        const response = await candidateApi.getFormations();
        if (response.success) {
            setFormations(response.formations);
            setLoading(false);
        } else {
            toast.error(t(response.message));
        }
    };

    const subHeaderFilterComponent = useMemo(() => {
        return <Filter onFilter={e => setFilterText(e.target.value)} filterText={filterText}/>;
    }, [filterText]);

    const handlePresent = async(data) => {
        const response = await candidateApi.updateTrainingSessionPresent(data);
        if (response.success) {
            setFormations(response.formations);
        } else {
            toast.error(t(response.message));
        }
    }

    const columns = [
        {
            name: t('name'),
            sortable: true,
            wrap: true,
            selector: 'name'
        },
        {
            name: t('description'),
            sortable: true,
            wrap: true,
            selector: 'description'
        },
        {
            name: t('datededebut'),
            sortable: true,
            wrap: true,
            selector: 'start'
        },
        {
            name: t('datedefin'),
            center: true,
            selector: 'end'
        },
        {
            name: t('presence'),
            center: true,
            cell: (row) => <>{row.present ? <span className="text-success"><FontAwesomeIcon icon={faCheckCircle}/></span> : <span className="text-danger"><FontAwesomeIcon icon={faXmarkCircle}/></span>}</>
        },
        {
            name: t(""),
            sortable: true,
            wrap: true,
            cell: (row) => <FormationsListActions row={row} handlePresent={handlePresent}/>,
        },
    ];

    useEffect(() => {
        getFormations();
    }, []);

    if(formations && !loading) {
        return (
            <DataTable
                columns={columns}
                noDataComponent={t("aucuneformationstrouvees")}
                data={filterHelper.filteredItems(formations, filterText)}
                keyField="key"
                pagination
                noHeader
                subHeader
                highlightOnHover
                subHeaderComponent={subHeaderFilterComponent}
                paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                paginationComponentOptions={{
                    rowsPerPageText: t('lignesparpage'),
                    rangeSeparatorText: t('sur'),
                    noRowsPerPage: false,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: 'All'
                }}
            />
        )
    }else{
        return (
            <Loader/>
        );
    }

}

export default List;
