export const SHOW_ADDRESS = 1;
export const EDIT_ADDRESS = 2;
export const SHOW_PHONES = 3;
export const EDIT_PHONES = 4;
export const SHOW_SKILLS = 5;
export const SHOW_LANGUAGES = 6;
export const SHOW_EXPERIENCES = 7;
export const SHOW_DIPLOMAS = 8;
export const LIST_INTERVIEWS = 9;
export const LIST_JOBS = 10;
export const LIST_FORMS = 11;
export const LIST_DOCUMENTS = 12;
export const SHOW_DOCUMENTS_CV = 13;
export const SHOW_DOCUMENTS_CONTRACT = 14;
export const SHOW_DOCUMENTS_OTHERS = 15;
export const EDIT_DOCUMENTS = 16;
export const LIST_CANDIDATURES = 17;
export const LIST_EVALUATIONS = 18;
export const LIST_FORMATIONS = 19;
export const EDIT_PROFILE = 20;
export const CANDIDATURE_HISTORY = 21;
export const LIST_CONVERSATIONS = 22;
export const SEND_CONVERSATION_MESAGE = 23;
export const LIST_INTERNAL_JOBS = 24;
export const APPLY_INTERNAL_JOBS = 25;
