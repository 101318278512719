import React, {
    useState,
    useRef,
    forwardRef,
    useImperativeHandle,
} from "react";
import { CandidateApi } from "services";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Grid, Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { EDIT_ADDRESS } from "helpers/accessSubmodule";
import { setUser } from "redux/slices/AuthSlice";

import "./ProfileForm.scss";

import ProfileAddressForm from "./ProfileAddressForm";
import ProfileInfoForm from "./ProfileInfoForm";

const ProfileForm = forwardRef((props, ref) => {
    const candidateApi = new CandidateApi();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const formRef = useRef();

    const { user, access } = useSelector((state) => state.auth);
    const { module, submodules } = access;

    const [address, setAddress] = useState(user.address);
    const [googleAddress, setGoogleAdress] = useState(address.name);

    const [mobilePhone, setMobilePhone] = useState(user.phones.cell);
    const [homePhone, setHomePhone] = useState(user.phones.home);
    const [email, setEmail] = useState(user.email);
    const [tabValue, setTabValue] = useState("info");

    const phoneRegExp =
        /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    useImperativeHandle(ref, () => ({
        handleSubmitForm() {
            formRef.current.handleSubmit();
        },
    }));

    const submitForm = async () => {
        const data = {
            phones: { cell: mobilePhone, home: homePhone },
            email: email,
            address: address,
        };
        const response = await candidateApi.updateProfile(data);
        if (response.success) {
            dispatch(setUser({ user: response.profile }));
            toast.success(t(response.message));
            setTimeout(function() {
                props.modal.remove();
            },1000)
        } else {
            toast.error(t(response.message));
        }
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Box sx={{ width: "100%", typography: "body1" }}>
                    <TabContext value={tabValue}>
                        <Box
                            sx={{
                                borderBottom: 1,
                                borderColor: "divider",
                            }}
                        >
                            <TabList
                                onChange={handleTabChange}
                                aria-label={t("modificationduprofil")}
                            >
                                <Tab label={t("info")} value="info" />
                                {submodules.includes(EDIT_ADDRESS) && (
                                    <Tab label={t("adresse")} value="adresse" />
                                )}
                            </TabList>
                        </Box>

                        <Formik
                            initialValues={{
                                email: email,
                                mobilePhone: mobilePhone,
                                homePhone: homePhone,
                            }}
                            validationSchema={Yup.object().shape({
                                email: Yup.string()
                                    .email(t("entreruncourrielvalide"))
                                    .max(255)
                                    .required(t("courrielobligatoire")),
                                mobilePhone: Yup.string().matches(
                                    phoneRegExp,
                                    "numerodetelephoneinvalide"
                                ),
                                homePhone: Yup.string().matches(
                                    phoneRegExp,
                                    "numerodetelephoneinvalide"
                                ),
                            })}
                            onSubmit={(values, { setSubmitting }) => {
                                submitForm();
                            }}
                            innerRef={formRef}
                        >
                            {({
                                errors,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                touched,
                                values,
                                setFieldValue,
                            }) => (
                                <form noValidate onSubmit={handleSubmit}>
                                    <TabPanel value="info">
                                        <ProfileInfoForm
                                            errors={errors}
                                            handleBlur={handleBlur}
                                            touched={touched}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            setMobilePhone={setMobilePhone}
                                            setEmail={setEmail}
                                            setHomePhone={setHomePhone}
                                        />
                                    </TabPanel>

                                    {submodules.includes(EDIT_ADDRESS) && (
                                        <TabPanel value="adresse">
                                            <ProfileAddressForm
                                                address={address}
                                                setAddress={setAddress}
                                                googleAddress={googleAddress}
                                                setGoogleAdress={
                                                    setGoogleAdress
                                                }
                                            />
                                        </TabPanel>
                                    )}
                                </form>
                            )}
                        </Formik>
                    </TabContext>
                </Box>
            </Grid>
        </Grid>
    );
});

export default ProfileForm;
