import React from 'react'
import {Outlet, Navigate} from 'react-router-dom'
import AuthLayout from "../layout/AuthLayout";
import { useSelector } from "react-redux";

const LoginRoutes = () => {

    const isAuth = Boolean(useSelector((state) => state.auth.token));

    return (isAuth ? <Navigate to="/profile" replace/> : <AuthLayout><Outlet/></AuthLayout>)

}

export default LoginRoutes