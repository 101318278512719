import React, { useEffect } from "react";
import { useStore } from "react-redux";
import { INTERNAL_JOB } from "../../helpers/accessModule";
import { LIST_INTERNAL_JOBS } from "../../helpers/accessSubmodule";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { MainCard } from "../../components";
import List from "./InternalJobList";

const IntarnalJob = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const store = useStore();

    const { module, submodules } = store.getState().auth.access;

    useEffect(() => {
        if (!module.includes(INTERNAL_JOB)) {
            navigate("/403");
        }
    }, []);

    return (
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            {submodules.includes(LIST_INTERNAL_JOBS) && (
                <Grid item xs={12} md={12} lg={12}>
                    <MainCard title={t("listedespostesinternes")}>
                        <List />
                    </MainCard>
                </Grid>
            )}
        </Grid>
    );
};

export default IntarnalJob;
