import React from "react";
import { faEye, faPen } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import ResultModal from "./FormResultModal";
import { useTheme } from "@mui/material/styles";
import { ItemIconButton } from "components";
import NiceModal from "@ebay/nice-modal-react";

const RowActions = ({ row }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <>
            {row && row.hasResponse && (
                <ItemIconButton
                    title={t("voir")}
                    bgcolor={"primary.main"}
                    onClick={() =>
                        NiceModal.show(ResultModal, {
                            formResponseId: row.formResponseId,
                        })
                    }
                    icon={faEye}
                />
            )}

            {row && row.status === "waiting" && (
                <ItemIconButton
                    title={t("remplirleformulaire")}
                    bgcolor={theme.palette.warning.main}
                    onClick={() => window.open(row.urlForm)}
                    icon={faPen}
                />
            )}
        </>
    );
};

export default RowActions;
