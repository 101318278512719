import NiceModal, {bootstrapDialog, useModal} from "@ebay/nice-modal-react";
import BootstrapModal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React from "react";
import FormationsFilesList from "./FormationsFilesList";
import {useTranslation} from "react-i18next";

const ConversationDetailModal = NiceModal.create(({data}) => {

    const {t} = useTranslation();
    const modal = useModal();

    return (
        <BootstrapModal {...bootstrapDialog(modal)} size="lg" backdrop="static">
            <BootstrapModal.Header closeButton>
                <BootstrapModal.Title className="glow-dark-blue-color glow-modal-title">{t('documents')} : {data.name}</BootstrapModal.Title>
            </BootstrapModal.Header>

            <BootstrapModal.Body className="overflow-auto max-h-750px" style={{ bgColor: "#EEF0F8"}}>
                <FormationsFilesList data={data} />
            </BootstrapModal.Body>

            <BootstrapModal.Footer>
                <Button onClick={() => {modal.hide()}} className="btn btn-secondary btn-sm">{t("fermer")}</Button>
            </BootstrapModal.Footer>
        </BootstrapModal>
    );
});

export default ConversationDetailModal;