import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import CandidateApi from "../../services/Api/CandidateApi";
import FilterHelper from "../../helpers/filter";
import Filter from "../../_tools/Datatable/Filter";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { Loader } from "../../components";

const SkillsList = () => {
    const { t } = useTranslation();

    const filterHelper = new FilterHelper();
    const candidateApi = new CandidateApi();

    const [loading, setLoading] = useState(true);
    const [skills, setSkills] = useState([]);
    const [filterText, setFilterText] = useState("");

    const getSkills = async () => {
        const response = await candidateApi.getSkills();
        if (response.success) {
            setSkills(response.skills);
            setLoading(false);
        } else {
            toast.error(t(response.message));
        }
    };

    const columns = [
        {
            name: t("titre"),
            sortable: true,
            wrap: true,
            selector: "name",
        },
        {
            name: t("niveau"),
            sortable: true,
            wrap: true,
            selector: "level",
        },
    ];

    const dataTableConverter = (data) => {
        let dataTable = [];

        if (data) {
            data.map((value, index) => {
                dataTable.push({
                    key: index + Math.random(),
                    index: index,
                    id: value.id,
                    name: value.skill.description,
                    level: value.level ? value.level.description : "",
                });
            });
        }
        return dataTable;
    };

    const subHeaderFilterComponent = useMemo(() => {
        return (
            <Filter
                onFilter={(e) => setFilterText(e.target.value)}
                filterText={filterText}
            />
        );
    }, [filterText]);

    useEffect(() => {
        getSkills();
    }, []);

    if (skills && !loading) {
        return (
            <DataTable
                columns={columns}
                noDataComponent={t("aucunecompetencestrouvees")}
                data={dataTableConverter(skills)}
                keyField="key"
                noHeader
                highlightOnHover
            />
        );
    } else {
        return <Loader />;
    }
};

export default SkillsList;
