import React from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { activeItem } from "../redux/slices/MenuSlice";
import { useDispatch } from "react-redux";

const NotFoundPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const buttonHandler = () => {
        dispatch(activeItem({ openItem: ["profil"] }));
    };

    return (
        <Box sx={{ minHeight: "100vh" }}>
            <Grid
                container
                direction="column"
                justifyContent="flex-end"
                sx={{
                    minHeight: "60vh",
                }}
            >
                <Grid item xs={12}>
                    <Grid
                        xs={12}
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            minHeight: {
                                xs: "calc(50vh - 134px)",
                                md: "calc(50vh - 112px)",
                            },
                        }}
                    >
                        <Grid
                            item
                            container
                            justifyContent="center"
                            alignItems="center"
                            xs={12}
                        >
                            <Box
                                component="img"
                                sx={{
                                    maxHeight: "200px"
                                }}
                                alt="Glow in the cloud"
                                src="/img/logo/glow-blue-en.png"
                            />
                        </Grid>

                        <Grid
                            item
                            container
                            justifyContent="center"
                            alignItems="center"
                            direction="column"
                            gap={2}
                        >
                            <Typography variant="h1">404</Typography>
                            <Typography variant="h6">
                                {t("lapagequevousrechercheznexistepas")}
                            </Typography>
                            <Button
                                variant="contained"
                                component={Link}
                                to="/profile"
                                onClick={buttonHandler()}
                            >
                                {t("retour")}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default NotFoundPage;
