import { useState } from "react"
import { useIdleTimer } from "react-idle-timer"

const IdleTimeout = ({ onIdle, idleTime, promptTimeBeforeIdle }) => {

    const idleTimeout = 1000 * idleTime;

    const [isIdle, setIdle] = useState(false);

    const handleIdle = () => {
        setIdle(true);
        localStorage.removeItem("persist:root");
        window.location.reload(false);
    }

    const idleTimer = useIdleTimer({
        timeout: idleTimeout,
        promptBeforeIdle: 1000 * promptTimeBeforeIdle,
        onPrompt: onIdle,
        onIdle: handleIdle,
        debounce: 500
    })

    return {
        isIdle,
        setIdle,
        idleTimer
    }
}

export default IdleTimeout;