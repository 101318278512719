import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from 'i18next-http-backend';

i18next
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: "/locale/{{lng}}.json",
        },
        // lng: "en_CA",
        fallbackLng: {
            'default': ['fr_CA'],
            'en': ['en_CA'],
            'us': ['en_US'],
            'gb': ['en_GB', 'en_CA'],
            'fr': ['fr_CA', 'en_CA'],
            'it': ['it_IT', 'en_CA'],
            'de': ['de_DE', 'en_CA'],
            'es': ['es_ES', 'en_CA'],
            'fre': ['fr_FR', 'en_CA'],
        }, // use en if detected lng is not available
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        detection: {
            // order and from where user language should be detected
            order: ['querystring', 'cookie', 'localStorage', 'navigator'],

            // keys or params to lookup language from
            lookupQuerystring: 'lng',
            lookupCookie: 'i18next',
            lookupLocalStorage: 'i18nextLng',

            // cache user language on
            caches: ['localStorage', 'cookie'],
            excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

            // optional expire and domain for set cookie
            cookieMinutes: 10,
            cookieDomain: 'portalGlow',

            // only detect languages that are in the whitelist
            checkWhitelist: false,

            // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
            cookieOptions: {path: '/'}
        },
        react: {
            useSuspense: false
        }
    });

export default i18next;
