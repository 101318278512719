import React from "react";
import EvaluationDetailsModal from "./EvaluationsDetailsModal";
import { faEye } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { ItemIconButton } from "components";
import NiceModal from "@ebay/nice-modal-react";
import { useTheme } from "@mui/material/styles";

const EvaluationListActions = ({ row }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <>
            <ItemIconButton
                title={t("voir")}
                bgcolor={theme.palette.info.main}
                onClick={() =>
                    NiceModal.show(EvaluationDetailsModal, { data: row })
                }
                icon={faEye}
            />
        </>
    );
};

export default EvaluationListActions;
