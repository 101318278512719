import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, List, Typography } from "@mui/material";
import NavItem from "./NavItem";
import { AuthApi } from "services";

const NavGroup = ({ item }) => {
    const menu = useSelector((state) => state.menu);
    const { drawerOpen } = menu;
    const access = useSelector((state) => state.auth.access);
    const authApi = new AuthApi();

    const [module, setModule] = useState([]);

    const getAccess = async () => {
        let allowedModule = [];
        let allowedSubmodule = [];
        let response = await authApi.access();
        if (response.success) {
            response.access.forEach((element) => {
                allowedModule.push(element.id);
                element.submodules.forEach((item) => {
                    allowedSubmodule.push(item.id);
                });
            });
            setModule(allowedModule);
        }
    };

    const navCollapse = item.children?.map((menuItem) => {
        if (module.includes(menuItem.access)) {
            switch (menuItem.type) {
                case "collapse":
                    return (
                        <Typography
                            key={menuItem.id}
                            variant="caption"
                            color="error"
                            sx={{ p: 2.5 }}
                        >
                            collapse - only available in paid version
                        </Typography>
                    );
                case "item":
                    return (
                        <NavItem key={menuItem.id} item={menuItem} level={1} />
                    );

                default:
                    return (
                        <Typography
                            key={menuItem.id}
                            variant="h6"
                            color="error"
                            align="center"
                        >
                            Fix - Group Collapse or Items
                        </Typography>
                    );
            }
        }
    });

    useEffect(() => {
        if (!access.length) {
            getAccess();
        } else {
            setModule(access.module);
        }
    }, []);

    return (
        <List
            subheader={
                item.title &&
                drawerOpen && (
                    <Box sx={{ pl: 3, mb: 1.5 }}>
                        <Typography variant="subtitle2" color="textSecondary">
                            {item.title}
                        </Typography>
                        {/* only available in paid version */}
                    </Box>
                )
            }
            sx={{ mb: drawerOpen ? 1.5 : 0, py: 0, zIndex: 0 }}
        >
            {navCollapse}
        </List>
    );
};

export default NavGroup;
