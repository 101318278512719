import Axios from "./AxioConfig";

class LanguageApi {

    setApiLanguage(lang) {
        const data = {
            lang: localStorage.getItem('i18nextLng')
        };
        return Axios.post('/api/language/change', data).then((response => {
            return response.data;
        }));
    }
}

export default LanguageApi