import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useTheme } from "@mui/material/styles";
import { Box, Toolbar, useMediaQuery } from "@mui/material";

import Drawer from "./Drawer";
import Header from "./Header";
import IdleTimeoutModal from "_tools/IdleTimeout/IdleTimeoutModal";
import { openDrawer } from "../../redux/slices/MenuSlice";

const MainLayout = () => {
    const theme = useTheme();
    const matchDownLG = useMediaQuery(theme.breakpoints.down("lg"));
    const matchDownMD = useMediaQuery(theme.breakpoints.down("md"));
    const dispatch = useDispatch();

    const { drawerOpen } = useSelector((state) => state.menu);

    // drawer toggler
    const [open, setOpen] = useState(drawerOpen);
    const handleDrawerToggle = () => {
        setOpen(!open);
        dispatch(openDrawer({ drawerOpen: !open }));
    };

    // set media wise responsive drawer
    useEffect(() => {
        setOpen(!matchDownLG);
        dispatch(openDrawer({ drawerOpen: !matchDownLG }));
    }, [matchDownLG]);

    useEffect(() => {
        if (open !== drawerOpen) setOpen(drawerOpen);
    }, [drawerOpen]);

    return (
        <Box sx={{ display: "flex", width: "100%" }}>
            <Header open={open} handleDrawerToggle={handleDrawerToggle} />
            <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
            <Box
                component="main"
                sx={{ width: "100%", flexGrow: 1, p: { xs: 2, sm: 3 } }}
            >
                <Toolbar />
                {!matchDownMD ? (
                    <Box sx={{ marginTop: "60px" }}>
                        <Outlet />
                    </Box>
                ) : (
                    <Outlet />
                )}
            </Box>
            <IdleTimeoutModal />
        </Box>
    );
};

export default MainLayout;
