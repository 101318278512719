import React, {useEffect, useState} from "react";
import Select from 'react-select';
import { useTranslation } from "react-i18next";

const SelectField = ({ index, field, handleSelectChange, readOnlyForm }) => {
    const { t } = useTranslation();
    const [value, setValue] = useState();

    const handleSelectValue = (event) => {
        setValue(event);
        handleSelectChange(index, event);
    }

    /**
     * Convert selected value
     */
    const convertSelectedValue = (selected) => {
        let result = [];
        field.value.map(item => {
            if(item.value === selected) {
                result.push({label: item.label, value: item.value})
            }
        })
        setValue(result);
    }

    /**
     * Mount data
     */
    useEffect(() => {
        convertSelectedValue(field.selected);
    },[])

    /**
     * Render the select
     */
    return (
        <div className="p-2">
            <label htmlFor={field.id} className={`form-label fw-bolder m-2 ${field.required ? "required" : ""}`}>{field.label}</label>
            <Select
                id={field.id}
                name={field.id}
                value={value}
                onChange={handleSelectValue}
                options={field.value}
                isDisabled={readOnlyForm}
                placeholder={t('selectionner')}
            />
        </div>
    );
};

export default SelectField;