import React from "react";

const RecruitersColumnsList = ({ row }) => {
    return (
        <ul className="list-unstyled">
            {row.recruiters.map((recruiter, index) => {
                return <li key={index + Math.random()}>{recruiter.name}</li>;
            })}
        </ul>
    );
};

export default RecruitersColumnsList;
