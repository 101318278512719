import React, {useEffect, useMemo, useState} from 'react';
import TrainingApi from "services/Api/TrainingApi";
import {toast} from "react-toastify";
import DataTable from "react-data-table-component";
import Filter from "_tools/Datatable/Filter";
import FilterHelper from "helpers/filter";
import {useTranslation} from "react-i18next";
import { Loader } from "components";
import FormationsFilesListActions from "./FormationsFilesListActions";

const FormationsFilesList = ({ data }) => {

    const { t } = useTranslation();

    const trainingApi = new TrainingApi();
    const filterHelper = new FilterHelper();

    const [loading, setLoading] = useState(true);
    const [files, setFiles] = useState([]);
    const [filterText, setFilterText] = useState('');

    const getTrainingFiles = async () => {
        const response = await trainingApi.getTrainingFiles(data.trainingId);
        if (response.success) {
            setFiles(response.data);
            setLoading(false);
        } else {
            toast.error(t(response.message));
        }
    };

    const subHeaderFilterComponent = useMemo(() => {
        return <Filter onFilter={e => setFilterText(e.target.value)} filterText={filterText}/>;
    }, [filterText]);

    const columns = [
        {
            name: t('name'),
            sortable: true,
            wrap: true,
            selector: 'name'
        },
        {
            name: t('date'),
            sortable: true,
            wrap: true,
            selector: 'date'
        },
        {
            name: t(""),
            sortable: true,
            wrap: true,
            cell: (row) => <FormationsFilesListActions row={row} />,
        },
    ];

    useEffect(() => {
        getTrainingFiles();
    }, []);

    if(files && !loading) {
        return (
            <DataTable
                columns={columns}
                noDataComponent={t("aucundocumentstrouves")}
                data={filterHelper.filteredItems(files, filterText)}
                keyField="key"
                pagination
                noHeader
                subHeader
                highlightOnHover
                subHeaderComponent={subHeaderFilterComponent}
                paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                paginationComponentOptions={{
                    rowsPerPageText: t('lignesparpage'),
                    rangeSeparatorText: t('sur'),
                    noRowsPerPage: false,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: 'All'
                }}
            />
        )
    }else{
        return (
            <Loader/>
        );
    }

}

export default FormationsFilesList;
