export const acceptedUploadFileTypes = [
    'image/jpeg',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'image/jpg',
    'application/pdf',
    'image/png',
    'text/plain'
];