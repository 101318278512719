import React, { useEffect, useState } from "react";
import { AuthApi } from "../../services";
import {
    SHOW_SKILLS,
    SHOW_LANGUAGES,
    SHOW_EXPERIENCES,
    SHOW_DIPLOMAS,
} from "../../helpers/accessSubmodule";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import ProfileDetails from "./Details";
import { MainCard } from "../../components";
import SkillsList from "./SkillsList";
import LanguagesList from "./LanguagesList";
import ExperiencesList from "./ExperiencesList";
import DiplomasList from "./DiplomasList";
import { useSelector } from "react-redux";

const cardSX = {
    maxHeight: "500px",
    height: "500px",
    overflow: "auto",
};

const Profile = () => {

    const { t } = useTranslation();
    const authApi = new AuthApi();

    const access = useSelector((state) => state.auth.access);
    
    const [submodules, setSubmodule] = useState([]);

    const getAccess = async () => {
        let allowedModule = [];
        let allowedSubmodule = [];
        let response = await authApi.access();
        if (response.success) {
            response.access.forEach((element) => {
                allowedModule.push(element.id);
                element.submodules.forEach((item) => {
                    allowedSubmodule.push(item.id);
                });
            });
            setSubmodule(allowedSubmodule)
        }
    };

    useEffect(() => {
        
        if(!access.length) {
            getAccess();
        }else{
            setSubmodule(access.submodules)
        }

    }, []);

    if (submodules.length) {
        return (
            <Grid container rowSpacing={4.5} columnSpacing={2.75}>
                <Grid item xs={12} md={4} lg={4}>
                    <Grid sx={cardSX}>
                        <ProfileDetails />
                    </Grid>
                </Grid>

                {submodules.includes(SHOW_SKILLS) && (
                    <Grid item xs={12} md={4} lg={4}>
                        <MainCard title={t("competences")} sx={cardSX}>
                            <SkillsList />
                        </MainCard>
                    </Grid>
                )}

                {submodules.includes(SHOW_LANGUAGES) && (
                    <Grid item xs={12} md={4} lg={4}>
                        <MainCard title={t("languages")} sx={cardSX}>
                            <LanguagesList />
                        </MainCard>
                    </Grid>
                )}

                {submodules.includes(SHOW_EXPERIENCES) && (
                    <Grid item xs={12} md={6} lg={6}>
                        <MainCard title={t("experiences")} sx={cardSX}>
                            <ExperiencesList />
                        </MainCard>
                    </Grid>
                )}

                {submodules.includes(SHOW_DIPLOMAS) && (
                    <Grid item xs={12} md={6} lg={6}>
                        <MainCard title={t("diplomes")} sx={cardSX}>
                            <DiplomasList />
                        </MainCard>
                    </Grid>
                )}
            </Grid>
        );
    }
};

export default Profile;
