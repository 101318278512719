import React from "react";
import { faMessage } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import ConversationDetailModal from "./ConversationDetailModal";
import { ItemIconButton } from "components";
import NiceModal from "@ebay/nice-modal-react";

const ConversationsListActions = ({ row, callBack }) => {

    const { t } = useTranslation();

    return (
        <>
            <ItemIconButton
                title={t("detail")}
                bgcolor={"info.main"}
                onClick={() =>
                    NiceModal.show(ConversationDetailModal, {
                        data: row,
                        callBack: callBack
                    })
                }
                icon={faMessage}
                counter={row.messagesNoRead}
            />
        </>
    );
};

export default ConversationsListActions;
