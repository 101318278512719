import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import NiceModal, { bootstrapDialog, useModal } from "@ebay/nice-modal-react";
import BootstrapModal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import InternalJobDetails from "./InternalJobDetails";

const InternalJobDetailsModal = NiceModal.create(({ data }) => {
    const { t } = useTranslation();
    const modal = useModal();

    return (
        <BootstrapModal {...bootstrapDialog(modal)} size="xl">
            <BootstrapModal.Header closeButton>
                <BootstrapModal.Title className="glow-dark-blue-color glow-modal-title">
                    {t("detailduposte")}
                </BootstrapModal.Title>
            </BootstrapModal.Header>

            <BootstrapModal.Body className="overflow-auto max-h-750px">
                <InternalJobDetails data={data} modal={modal}/>
            </BootstrapModal.Body>
        </BootstrapModal>
    );
});

export default InternalJobDetailsModal;
