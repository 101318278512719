import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
    Login,
    ResetPassword,
    ForgotPassword,
    Profile,
    NotFoundPage,
    ForbiddenPage,
    Interviews,
    Jobs,
    Forms,
    Documents,
    Candidatures,
    Evaluations,
    Formations,
    Conversations,
    InternalJob
} from "pages";
import ProtectedRoutes from "./ProtectedRoutes";
import LoginRoutes from "./LoginRoutes";

const RouteNavigation = () => {
    return (
        <BrowserRouter>
            <Routes>
                {/* Login Route */}
                <Route element={<LoginRoutes />}>
                    <Route path="/" element={<Login />} />
                    <Route path="/login" element={<Login />} />
                    <Route
                        path="/forgot-password"
                        element={<ForgotPassword />}
                    />
                    <Route
                        path="/reset-password/:token"
                        element={<ResetPassword />}
                    />
                </Route>

                {/* Protected Route */}
                <Route element={<ProtectedRoutes />}>
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/interviews" element={<Interviews />} />
                    <Route path="/jobs" element={<Jobs />} />
                    <Route path="/forms" element={<Forms />} />
                    <Route path="/documents" element={<Documents />} />
                    <Route path="/candidatures" element={<Candidatures />} />
                    <Route path="/evaluations" element={<Evaluations />} />
                    <Route path="/formations" element={<Formations />} />
                    <Route path="/conversations" element={<Conversations />} />
                    <Route path="/internal-job" element={<InternalJob />} />
                </Route>

                <Route path="/403" element={<ForbiddenPage />} />
                <Route path="/404" element={<NotFoundPage />} />
                <Route path="*" element={<Navigate to="/404" />} />
            </Routes>
        </BrowserRouter>
    );
};

export default RouteNavigation;
