import React, {useEffect, useMemo, useState} from 'react';
import {toast} from "react-toastify";
import DataTable from 'react-data-table-component';
import { Loader } from "../../components";
import CandidateApi from "../../services/Api/CandidateApi";
import FilterHelper from "../../helpers/filter";
import Filter from "../../_tools/Datatable/Filter";
import {useTranslation} from "react-i18next";

const PastMandatesList = () => {

    const { t } = useTranslation();

    const filterHelper = new FilterHelper();
    const candidateApi = new CandidateApi();

    const [loading, setLoading] = useState(true);
    const [pastMandates, setPastMandates] = useState([]);
    const [filterText, setFilterText] = useState('');

    const getPastMandates = async () => {
        const response = await candidateApi.getPastMandates();
        if (response.success) {
            setPastMandates(response.pastMandates);
            setLoading(false);
        } else {
            toast.error(t(response.message));
        }
    }

    const columns = [
        {
            name: t('mandat'),
            sortable: true,
            wrap: true,
            selector: 'id'
        },
        {
            name: t('client'),
            sortable: true,
            wrap: true,
            cell: (row) => row.client.name
        },
        {
            name: t('datedebut'),
            sortable: true,
            wrap: true,
            selector: 'start'
        },
        {
            name: t('datefin'),
            sortable: true,
            wrap: true,
            selector: 'end'
        },
    ];

    const subHeaderFilterComponent = useMemo(() => {
        return <Filter onFilter={e => setFilterText(e.target.value)} filterText={filterText}/>;
    }, [filterText]);

    useEffect(() => {
        getPastMandates();
    }, [])

    if(pastMandates && !loading) {
        return (
            <DataTable
                columns={columns}
                noDataComponent={t("aucunemploistrouves")}
                data={filterHelper.filteredItems(pastMandates, filterText)}
                keyField="key"
                pagination
                noHeader
                subHeader
                highlightOnHover
                subHeaderComponent={subHeaderFilterComponent}
                paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                paginationComponentOptions={{
                    rowsPerPageText: t('lignesparpage'),
                    rangeSeparatorText: t('sur'),
                    noRowsPerPage: false,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: 'All'
                }}
            />
        )
    }else{
        return (
            <Loader/>
        );
    }
    
}

export default PastMandatesList;
