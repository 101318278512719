import * as React from "react";
import { SvgIcon } from '@mui/material';

export default function MicrosoftTeamIcon() {
    return (
        <SvgIcon
            color="blueviolet"
            version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="480.000000pt" height="480.000000pt" viewBox="0 0 480.000000 480.000000"
            preserveAspectRatio="xMidYMid meet"
        >
            <g transform="translate(0.000000,480.000000) scale(0.100000,-0.100000)"
               fill="#000000" stroke="none">
                <path d="M2414 4291 c-321 -51 -549 -353 -504 -670 9 -61 26 -117 67 -208 4
-10 -143 -13 -716 -13 -591 0 -727 -3 -754 -14 -39 -16 -82 -61 -96 -99 -15
-39 -15 -1735 0 -1774 15 -42 68 -92 109 -103 22 -6 364 -10 901 -10 l866 0
34 23 c18 12 44 38 56 56 l23 34 0 797 0 796 98 0 c166 1 307 59 422 174 115
115 173 257 173 420 0 111 -18 182 -71 285 -110 210 -373 342 -608 306z m-604
-1476 l0 -84 -152 -3 -153 -3 -3 -412 -2 -413 -100 0 -100 0 -2 413 -3 412
-152 3 -153 3 0 84 0 85 410 0 410 0 0 -85z"/>
                <path d="M3703 3985 c-159 -43 -265 -159 -295 -325 -48 -255 198 -500 454
-452 174 32 298 156 330 330 22 117 -22 253 -110 341 -56 56 -107 85 -189 106
-70 18 -121 18 -190 0z"/>
                <path d="M3345 2791 c68 -17 127 -74 145 -142 12 -45 8 -1255 -5 -1320 l-7
-35 69 -31 c100 -46 147 -56 253 -57 266 0 489 167 576 434 16 49 18 106 22
512 2 326 0 469 -8 499 -15 56 -81 123 -135 138 -27 7 -188 11 -490 10 -247
-1 -436 -4 -420 -8z"/>
            </g>
        </SvgIcon>
    );
}
