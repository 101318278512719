import React from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "./fileViewer.scss";
import { useTranslation } from "react-i18next";

const FileViewer = ({ fileObject }) => {
    const { t } = useTranslation();

    return (
        <DocViewer
            style={{ width: "100%", height: "700px" }}
            pluginRenderers={DocViewerRenderers}
            documents={[
                { uri: fileObject.urlViewer, fileType: fileObject.extension },
            ]}
        />
    );
};

export default FileViewer;
