import React, { useEffect, useState } from "react";
import CandidateApi from "../../services/Api/CandidateApi";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import { Loader } from "../../components";

const ExperiencesList = () => {
    const { t } = useTranslation();
    const candidateApi = new CandidateApi();

    const [loading, setLoading] = useState(true);
    const [experiences, setExperiences] = useState([]);

    const getExperiences = async () => {
        let response = await candidateApi.getExperiences();
        if (response.success) {
            setExperiences(response.experiences);
            setLoading(false);
        } else {
            toast.error(t(response.message));
        }
    };

    const columns = [
        {
            name: t("compagnie"),
            sortable: true,
            wrap: true,
            selector: "company",
        },
        {
            name: t("poste"),
            sortable: true,
            wrap: true,
            selector: "position",
        },
        {
            name: t("secteur"),
            sortable: true,
            wrap: true,
            selector: "sector",
        },
        {
            name: t("periode"),
            sortable: true,
            wrap: true,
            selector: "date",
        },
    ];

    const dataTableConverter = (data) => {
        let dataTable = [];

        if (data) {
            data.map((value, index) => {
                dataTable.push({
                    key: index + Math.random(),
                    index: index,
                    id: value.id,
                    company: value.company,
                    position: value.description,
                    posteCandidate: value.posteCandidate,
                    sector: value.sector.description,
                    date: value.to ? value.from + " / " + value.to : value.from,
                });
            });
        }
        return dataTable;
    };

    useEffect(() => {
        getExperiences();
    }, []);

    if (experiences && !loading) {
        return (
            <DataTable
                columns={columns}
                noDataComponent={t("aucuneexperiencestrouvees")}
                data={dataTableConverter(experiences)}
                keyField="key"
                noHeader
                highlightOnHover
            />
        );
    } else {
        return <Loader />;
    }
};

export default ExperiencesList;
