import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Tooltip, Box, Badge } from "@mui/material";

const ItemIconButton = ({
    title,
    size = "small",
    onClick,
    icon,
    iconSize = "lg",
    counter = 0,
}) => {
    return (
        <Box m={1}>
            <Tooltip title={title} placement="top" arrow>
                <IconButton
                    disableRipple
                    color="secondary"
                    size={size}
                    sx={{
                        color: "text.primary",
                        bgcolor: "grey.100",
                        borderRadius: "5px"
                    }}
                    onClick={onClick}
                >
                    <Badge
                        badgeContent={counter > 0 ? counter : null}
                        color="error"
                    >
                        <FontAwesomeIcon
                            icon={icon}
                            size={iconSize}
                        />
                    </Badge>
                </IconButton>
            </Tooltip>
        </Box>
    );
};

export default ItemIconButton;
