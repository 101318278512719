
import React, { useRef  } from "react";
import {useTranslation} from "react-i18next";
import { EDIT_PHONES } from "helpers/accessSubmodule";
import { useSelector } from "react-redux";
import {
    FormHelperText,
    Grid,
    InputLabel,
    Stack,
    TextField,
    styled,
} from "@mui/material";

const ProfileInfoForm = ({ 
    errors,
    handleBlur,
    touched,
    values,
    setFieldValue,
    setEmail,
    setHomePhone,
    setMobilePhone
}) => {

    const {t} = useTranslation();
 
    const { user, access } = useSelector((state) => state.auth);
    const { module, submodules } = access;

    const handleEmailChange = (e, setFieldValue) => {
        setFieldValue("email", e.target.value);
        setEmail(e.target.value);
    };

    const handleHomePhoneChange = (e, setFieldValue) => {
        setFieldValue("homePhone", e.target.value);
        setHomePhone(e.target.value);
    };

    const handleMobilePhoneChange = (e, setFieldValue) => {
        setFieldValue("mobilePhone", e.target.value);
        setMobilePhone(e.target.value);
    };

    return (
        <Grid item xs={12}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Stack spacing={1}>
                        <StyledInputLabel
                            htmlFor="email-profile"
                            required
                        >
                            {t("courriel")}
                        </StyledInputLabel>
                        <StyledTextField
                            id="email-profile"
                            type="email"
                            value={values.email}
                            name="email"
                            onBlur={handleBlur}
                            onChange={(e) =>
                                handleEmailChange(
                                    e,
                                    setFieldValue
                                )
                            }
                            fullWidth
                            error={Boolean(
                                touched.email &&
                                    errors.email
                            )}
                        />
                        {touched.email &&
                            errors.email && (
                                <FormHelperText
                                    error
                                    id="standard-weight-helper-text-email-profile"
                                >
                                    {
                                        errors.email
                                    }
                                </FormHelperText>
                            )}
                    </Stack>
                </Grid>
                {submodules.includes(
                    EDIT_PHONES
                ) && (
                    <Grid item xs={6}>
                        <Stack spacing={1}>
                            <StyledInputLabel htmlFor="home-phone">
                                {t(
                                    "telephonemaison"
                                )}
                            </StyledInputLabel>
                            <StyledTextField
                                id="home-phone"
                                type="tel"
                                value={
                                    values.homePhone
                                }
                                name="homePhone"
                                onBlur={
                                    handleBlur
                                }
                                onChange={(e) =>
                                    handleHomePhoneChange(
                                        e,
                                        setFieldValue
                                    )
                                }
                                fullWidth
                                error={Boolean(
                                    touched.homePhone &&
                                        errors.homePhone
                                )}
                            />
                            {touched.homePhone &&
                                errors.homePhone && (
                                    <FormHelperText
                                        error
                                        id="standard-weight-helper-text-home-phone"
                                    >
                                        {
                                            errors.homePhone
                                        }
                                    </FormHelperText>
                                )}
                        </Stack>
                    </Grid>
                )}
                {submodules.includes(
                    EDIT_PHONES
                ) && (
                    <Grid item xs={6}>
                        <Stack spacing={1}>
                            <StyledInputLabel htmlFor="mobile-phone">
                                {t(
                                    "telephonecellulaire"
                                )}
                            </StyledInputLabel>
                            <StyledTextField
                                id="mobile-home"
                                type="tel"
                                value={
                                    values.mobilePhone
                                }
                                name="mobilePhone"
                                onBlur={
                                    handleBlur
                                }
                                onChange={(e) =>
                                    handleMobilePhoneChange(
                                        e,
                                        setFieldValue
                                    )
                                }
                                fullWidth
                                error={Boolean(
                                    touched.mobilePhone &&
                                        errors.mobilePhone
                                )}
                            />
                            {touched.mobilePhone &&
                                errors.mobilePhone && (
                                    <FormHelperText
                                        error
                                        id="standard-weight-helper-text-mobile-phone"
                                    >
                                        {
                                            errors.mobilePhone
                                        }
                                    </FormHelperText>
                                )}
                        </Stack>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}

const StyledTextField = styled(TextField)(({ theme }) => ({
    ".MuiInputBase-root": {
        height: 40,
    },
    ".MuiInputBase-input": {
        height: 5,
    },
    ".MuiInputBase-input": {
        fontSize: "14px",
    },
}));

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
    fontSize: "0.9rem",
}));

export default ProfileInfoForm;