import {createSlice} from '@reduxjs/toolkit';

export const MenuSlice = createSlice({
    name: 'menu',
    initialState: {
        openItem: ['profil'],
        openComponent: 'buttons',
        drawerOpen: false,
        componentDrawerOpen: true
    },
    reducers: {
        activeItem(state, action) {
            state.openItem = action.payload.openItem;
        },

        activeComponent(state, action) {
            state.openComponent = action.payload.openComponent;
        },

        openDrawer(state, action) {
            state.drawerOpen = action.payload.drawerOpen;
        },

        openComponentDrawer(state, action) {
            state.componentDrawerOpen = action.payload.componentDrawerOpen;
        },
        clearMenu(state) {
            state.openItem = ['profil'];
            state.openComponent = 'buttons';
            state.drawerOpen = false;
            state.componentDrawerOpen = true;
        }
    }
});

export const {activeItem, activeComponent, openDrawer, openComponentDrawer, clearMenu} = MenuSlice.actions;

export default MenuSlice.reducer;
