import NiceModal, {bootstrapDialog, useModal} from "@ebay/nice-modal-react";
import BootstrapModal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React from "react";
import FormResult from "./FormResult";
import {useTranslation} from "react-i18next";

const ResultModal = NiceModal.create(({formResponseId}) => {

    const {t} = useTranslation();
    const modal = useModal();

    return (
        <BootstrapModal {...bootstrapDialog(modal)} size="lg">
            <BootstrapModal.Header closeButton>
                <BootstrapModal.Title className="glow-dark-blue-color glow-modal-title">{t("resultatduformulaire")}</BootstrapModal.Title>
            </BootstrapModal.Header>

            <BootstrapModal.Body className="overflow-auto max-h-750px">
                <FormResult formResponseId={formResponseId} />
            </BootstrapModal.Body>

            <BootstrapModal.Footer>
            <Button onClick={modal.hide} className="btn btn-secondary btn-sm">{t("fermer")}</Button>
            </BootstrapModal.Footer>
        </BootstrapModal>
    );
});

export default ResultModal;