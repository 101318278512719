import NiceModal, { bootstrapDialog, useModal } from "@ebay/nice-modal-react";
import BootstrapModal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React, { useRef, useState } from "react";
import ConversationDetail from "./ConversationDetail";
import { useTranslation } from "react-i18next";
import { Grid, TextField, styled } from "@mui/material";

const ConversationDetailModal = NiceModal.create(({ data, callBack }) => {
    const { t } = useTranslation();
    const modal = useModal();
    const ConversationModalRef = useRef();
    const [message, setMessage] = useState("");

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    }

    return (
        <BootstrapModal {...bootstrapDialog(modal)} size="lg" backdrop="static">
            <BootstrapModal.Header closeButton>
                <BootstrapModal.Title className="glow-dark-blue-color glow-modal-title">
                    {t("conversationavec")} : {data.managerName}
                </BootstrapModal.Title>
            </BootstrapModal.Header>

            <BootstrapModal.Body
                className="overflow-auto max-h-700px"
                style={{ bgColor: "#EEF0F8" }}
            >
                <ConversationDetail
                    message={message}
                    data={data}
                    modal={modal}
                    ref={ConversationModalRef}
                />
            </BootstrapModal.Body>

            <BootstrapModal.Footer>
                <Grid container>
                    <Grid item xs={12}>
                        <StyledTextField
                            id="message"
                            type="text"
                            placeholder={t("saisissezunmessage")}
                            onChange={handleMessageChange}
                            value={message}
                            fullWidth
                            multiline
                        />
                    </Grid>
                    <Grid container justifyContent="flex-end" sx={{ marginTop: '5px'}}>
                       
                        <Button
                            onClick={() => {
                                ConversationModalRef.current.handleExpandedSend();
                                callBack();
                                setMessage("");
                            }}
                            className="btn btn-success btn-sm m-1"
                        >
                            {t("envoyer")}
                        </Button>

                        <Button
                            onClick={() => {
                                modal.hide();
                                callBack();
                            }}
                            className="btn btn-secondary btn-sm m-1"
                        >
                            {t("fermer")}
                        </Button>
                    </Grid>
                </Grid>
            </BootstrapModal.Footer>
        </BootstrapModal>
    );
});

const StyledTextField = styled(TextField)(({ theme }) => ({
    ".MuiInputBase-input": {
        height: 5,
        fontSize: "14px",
    },
}));

export default ConversationDetailModal;
