import {createSlice} from "@reduxjs/toolkit";

export const AuthSlice = createSlice({
    name: "auth",
    initialState: {
        token: null,
        access: [],
        user: null,
        company: null,
    },
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload.user;
        },
        setAccess: (state, action) => {
            state.access = action.payload.access;
        },
        setCompany: (state, action) => {
            state.company = action.payload.company;
        },
        setToken: (state, action) => {
            state.token = action.payload.token;
        },
        clearAuth: (state) => {
            state.token = null;
            state.user = null;
            state.company = null;
            state.access = [];
        },
    },
});

export const {setToken, clearAuth, setUser, setAccess, setCompany} = AuthSlice.actions;

export default AuthSlice.reducer;
