import React from "react";
import { faMessage } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import ConversationDetailModal from "pages/Conversations/ConversationDetailModal";
import { ItemIconButton } from "components";
import NiceModal from "@ebay/nice-modal-react";
import { useStore } from "react-redux";
import { SEND_CONVERSATION_MESAGE } from "helpers/accessSubmodule";

const CurrentMandatesListActions = ({ row, callBack }) => {
    const { t } = useTranslation();
    const store = useStore();
    const { submodules } = store.getState().auth.access;

    return (
        <>
            {submodules.includes(SEND_CONVERSATION_MESAGE) && (
                <ItemIconButton
                    title={t("conversation")}
                    bgcolor={"grey.300"}
                    onClick={() =>
                        NiceModal.show(ConversationDetailModal, {
                            data: row,
                            callBack: callBack,
                        })
                    }
                    icon={faMessage}
                    counter={row.messagesNoRead}
                />
            )}
        </>
    );
};

export default CurrentMandatesListActions;
