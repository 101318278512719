import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Select,
    MenuItem,
} from "@mui/material";
import { LanguageApi } from "services";

const LanguageSelector = () => {

    const { i18n } = useTranslation();
    const languageApi = new LanguageApi();

    const [language, setLanguage] = useState("");

    const changeLanguage = (event) => {
        setLanguage(event.target.value);
        i18n.changeLanguage(event.target.value);
        localStorage.setItem('i18nextLng', event.target.value);
        setApiLanguage();
        window.location.reload();

    }

    const setApiLanguage = async () => {
        await languageApi.setApiLanguage(language);
    };

    useEffect(() => {
        let lang = localStorage.getItem('i18nextLng');
        if(!lang) {
            lang = "en_CA";
            localStorage.setItem('i18nextLng', lang);
        }
        setLanguage(lang.replace(/-/g, '_'));
    }, []);

    return (
        <Select
            value={language}
            onChange={changeLanguage}
            sx={{
                "& fieldset": {
                    border: "none",
                },
                height: 40
            }}
        >
            <MenuItem value="en_CA"><img width="30px" src="img/flags/CA.png" alt="CA flag" className="me-2"/>EN</MenuItem>
            <MenuItem value="fr_CA"><img width="30px" src="img/flags/CA.png" className="me-2" alt="CA flag"/>FR</MenuItem>
            <MenuItem value="fr_FR"><img width="30px" src="img/flags/FR.png" className="me-2" alt="CA flag"/>FR</MenuItem>
            <MenuItem value="en_US"><img width="30px" src="img/flags/US.png" className="me-2" alt="CA flag"/>US</MenuItem>
            <MenuItem value="en_GB"><img width="30px" src="img/flags/GB.png" className="me-2" alt="CA flag"/>GB</MenuItem>
            <MenuItem value="es_ES"><img width="30px" src="img/flags/ES.png" className="me-2" alt="CA flag"/>ES</MenuItem>
            <MenuItem value="de_DE"><img width="30px" src="img/flags/DE.png" className="me-2" alt="CA flag"/>DE</MenuItem>
            <MenuItem value="it_IT"><img width="30px" src="img/flags/IT.png" className="me-2" alt="CA flag"/>IT</MenuItem>
        </Select>
    )
}

export default LanguageSelector;
