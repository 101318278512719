import React, {useEffect, useMemo, useState} from 'react';
import {toast} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DataTable from 'react-data-table-component';
import moment from 'moment';
import {faPhone, faUserFriends, faVideo} from "@fortawesome/free-solid-svg-icons";
import { Loader } from "../../components";
import CandidateApi from "../../services/Api/CandidateApi";
import FilterHelper from "../../helpers/filter";
import Filter from "../../_tools/Datatable/Filter";
import InterviewsRecruitersList from "./InterviewsRecruitersList";
import {useTranslation} from "react-i18next";
import MicrosoftTeamIcon from "../../components/MicrosoftTeamIcon";

const InterviewsList = () => {

    const { t } = useTranslation();

    const filterHelper = new FilterHelper();
    const candidateApi = new CandidateApi();

    const [loading, setLoading] = useState(true);
    const [interviews, setInterviews] = useState([]);
    const [filterText, setFilterText] = useState('');

    const getInterviews = async () => {
        let response = await candidateApi.getInterviews();
        if (response.success) {
            setInterviews(response.interviews);
            setLoading(false);
        } else {
            toast.error(t(response.message));
        }
    }

    const columns = [
        {
            name: t('date'),
            sortable: true,
            wrap: true,
            selector: 'date'
        },
        {
            name: t('debut'),
            sortable: true,
            wrap: true,
            selector: 'from'
        },
        {
            name: t('fin'),
            sortable: true,
            wrap: true,
            selector: 'to'
        },
        {
            name: t('recruteurs'),
            sortable: true,
            wrap: true,
            cell: (row) => <InterviewsRecruitersList row={row} />,
        },
        {
            name: t('type'),
            center: true,
            selector: 'method'
        },
    ];

    const dataTableConverter = (data) => {
        let dataTable = [];

        if (data) {
            data.map((value, index) => {
                let icone = '';
                if (value.isPhone) {
                    icone = <label title={t('entrevuetelephonique')}><FontAwesomeIcon icon={faPhone} color="dodgerblue"/></label>;
                } else if (value.isInPerson) {
                    icone = <label title={t('entrevuesurplace')}><FontAwesomeIcon icon={faUserFriends} color="tomato"/></label>;
                } else if (value.isMicrosoftTeams) {
                    icone = <label title={t('entrevuemicrosoftteams')}><MicrosoftTeamIcon/></label>;
                } else {
                    if (value.isJobmaVideo) {
                        icone = <label title={t('entrevuevideo')}><FontAwesomeIcon icon={faVideo} color="lavender"/></label>;
                    } else if (value.isJobmaVideo && value.jobmaVideo.url) {
                        icone = <label title={t('entrevuevideo')}><a href={value.jobmaUrl} title={t('voir')}
                                                                     target="_blank"><FontAwesomeIcon
                            icon={faVideo}/></a></label>;
                    } else if (value.isJobmaLiveInterview && value.jobmaliveInterview.status === 'in_progress') {
                        icone = <label title={t('videoendirect')}><FontAwesomeIcon icon={faVideo} color="lavender"/></label>;
                    } else if (value.isJobmaLiveInterview && value.jobmaliveInterview.status === 'completed') {
                        icone = <label title={t('videoendirect')}><FontAwesomeIcon icon={faVideo} color="lavender"/></label>;
                    }
                }

                dataTable.push({
                    key: index + Math.random(),
                    index: index,
                    id: value.id,
                    description: value.comment,
                    date: moment(value.start).format("DD/MM/YYYY"),
                    from: moment(value.start).format("HH:mm"),
                    to: moment(value.end).format("HH:mm"),
                    termine: value.termine,
                    recruiters: value.recruiters,
                    method: icone,
                    deletable: true,
                })
            })
        }
        return dataTable;
    }

    const subHeaderFilterComponent = useMemo(() => {
        return <Filter onFilter={e => setFilterText(e.target.value)} filterText={filterText}/>;
    }, [filterText]);

    useEffect(() => {
        getInterviews();
    }, [])

    if(interviews && !loading) {
        return (
            <DataTable
                columns={columns}
                noDataComponent={t("aucunentretienstrouves")}
                data={filterHelper.filteredItems(dataTableConverter(interviews), filterText)}
                keyField="key"
                pagination
                noHeader
                subHeader
                highlightOnHover
                subHeaderComponent={subHeaderFilterComponent}
                paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                paginationComponentOptions={{
                    rowsPerPageText: t('lignesparpage'),
                    rangeSeparatorText: t('sur'),
                    noRowsPerPage: false,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: 'All'
                }}
            />
        )
    }else{
        return (
            <Loader/>
        );
    }
    
}

export default InterviewsList;
