import React from "react";
import { faFileAlt, faCheck, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import FormationsFilesListModal from "./FormationsFilesList/FormationsFilesListModal";
import { ItemIconButton } from "components";
import NiceModal from "@ebay/nice-modal-react";

const FormationsListActions = ({ row, handlePresent }) => {
    const { t } = useTranslation();
    return (
        <>
            <ItemIconButton
                title={t("documents")}
                bgcolor={"primary.main"}
                onClick={() =>
                    NiceModal.show(FormationsFilesListModal, {
                        data: row,
                    })
                }
                icon={faFileAlt}
            />

            <ItemIconButton
                title={row.present ? t("nonpresent") : t("present")}
                icon={row.present ? faXmark : faCheck}
                onClick={() => handlePresent(row)}
            />
        </>
    );
};

export default FormationsListActions;
