import React, {useEffect, useState} from 'react';
import {toast} from "react-toastify";
import FormGenerator from "../../_tools/FormGenerator/Form";
import CandidateApi from "../../services/Api/CandidateApi";
import {useTranslation} from "react-i18next";

const FormResult = ({formResponseId}) => {

    const { t } = useTranslation();

    const candidateApi = new CandidateApi();

    const [form, setForm] = useState([]);
    const [readOnlyForm, setReadOnlyForm] = useState([]);

    const handleFormSubmit = () => {
    }

    const getResponseFormFields = async () => {
        const response = await candidateApi.getResponseFormFields(formResponseId)
        if (response.success) {
            setForm(response.data.form);
            setReadOnlyForm(response.data.readOnlyForm)
        } else {
            toast.error(response.message);
            return null;
        }
    }

    useEffect(() => {
        getResponseFormFields()
    }, [])

    /**
     * Check if we have the result
     */
    if (form) {

        /**
         * Render the formulaire reponse
         */
        return (
            <>
                <FormGenerator formData={form} readOnlyForm={readOnlyForm} formSubmit={handleFormSubmit}
                               formId={'formResult'} t={t}/>
            </>
        )

    } else {
        /**
         * Return error message
         */
        return (
            <>
                {t('formulaireintrouvable')}
            </>
        )
    }
}

export default FormResult;