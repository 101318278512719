import Axios from "./AxioConfig";

class EmployeeApi {

    getEmployeeCie(email) {
        const data = {
            email: email
        };
        return Axios.post('/api/employee/cie', data).then((response => {
            return response.data;
        }));
    }
}

export default EmployeeApi