import {useTheme} from '@mui/material/styles';
import {AppBar, IconButton, Toolbar, useMediaQuery} from '@mui/material';

// project import
import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';

// assets
import {MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = ({open, handleDrawerToggle}) => {

    const {t} = useTranslation();
    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));

    const menu = useSelector((state) => state.menu);
    const { openItem } = menu;

    const iconBackColor = 'grey.100';
    const iconBackColorOpen = 'grey.200';

    const menuOpenItem = openItem[0];

    // common header
    const mainHeader = (
        <Toolbar>
            <IconButton
                disableRipple
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
                color="secondary"
                sx={{color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor, ml: {xs: 0, lg: -2}}}
            >
                {!open ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
            </IconButton>
            <HeaderContent/>
        </Toolbar>
    );

    // common header
    const subHeader = (
        <Toolbar
            sx={{
                borderTop: `1px solid ${theme.palette.divider}`,
                borderBottom: `1px solid ${theme.palette.divider}`,
            }}
        >
            <h6 className="fw-bold my-1 mr-5 glow-dark-blue-color">{t(menuOpenItem)}</h6>
        </Toolbar>
    );

    // app-bar params
    const appBar = {
        position: 'fixed',
        color: 'inherit',
        elevation: 0,
        sx: {
            borderBottom: `1px solid ${theme.palette.divider}`
            // boxShadow: theme.customShadows.z1
        }
    };

    return (
        <>
            {!matchDownMD ? (
                <AppBarStyled open={open} {...appBar}>
                    {mainHeader}
                    {subHeader}
                </AppBarStyled>
            ) : (
                <AppBar {...appBar}>
                    {mainHeader}
                </AppBar>
            )}
        </>
    );
};

export default Header;
