import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import CandidateApi from "../../services/Api/CandidateApi";
import FilterHelper from "../../helpers/filter";
import Filter from "../../_tools/Datatable/Filter";
import { useTranslation } from "react-i18next";
import { Loader } from "../../components";

const LanguagesList = () => {
    const { t } = useTranslation();

    const candidateApi = new CandidateApi();

    const [loading, setLoading] = useState(true);
    const [languages, setLanguages] = useState([]);

    const getLanguages = async () => {
        const response = await candidateApi.getLanguages();
        if (response.success) {
            setLanguages(response.languages);
            setLoading(false);
        } else {
            toast.error(t(response.message));
        }
    };

    const columns = [
        {
            name: t("titre"),
            sortable: true,
            wrap: true,
            selector: "name",
        },
        {
            name: t("niveau"),
            sortable: true,
            wrap: true,
            selector: "level",
        },
    ];

    const dataTableConverter = (data) => {
        let dataTable = [];

        if (data) {
            data.map((value, index) => {
                dataTable.push({
                    key: index + Math.random(),
                    index: index,
                    id: value.id,
                    name: value.language.description,
                    level: value.level ? value.level.description : "",
                });
            });
        }
        return dataTable;
    };

    useEffect(() => {
        getLanguages();
    }, []);

    if (languages && !loading) {
        return (
            <DataTable
                columns={columns}
                noDataComponent={t("aucunelanguestrouvees")}
                data={dataTableConverter(languages)}
                keyField="key"
                noHeader
                highlightOnHover
            />
        );
    } else {
        return <Loader />;
    }
};

export default LanguagesList;
