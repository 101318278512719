import React from "react";

const FileField = ({ index, field, handleFileChange, readOnlyForm, t }) => {

    /**
     * Check if the form is a formulaire reponse
     */
    if(readOnlyForm) {

        /**
         * Render the files
         */
        return (
            <div className="mb-3">
                <label htmlFor={field.id} className="form-label fw-bolder">{field.label}</label>
                <ul className="list-unstyled">
                    {field.value && field.value.map((item, index) => {
                        return (
                            <li key={item.id  + Math.random()}>
                                {item.name}
                            </li>
                        )
                    })}
                </ul>
            </div>
        );
    }else{

        /**
         * Render the input file
         */
        return (
            <li key={field.id} className="p-2">
                <label htmlFor={field.id} className={`fw-bolder m-2 ${field.required ? "required" : ""}`}>{field.label}</label>
                <input
                    type="file"
                    id={field.id}
                    name={field.id}
                    className="upload-file"
                    onChange={e => handleFileChange(index, e)}
                    encType="multipart/form-data"
                    multiple
                    accept="image/png, image/jpeg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                />
            </li>
        );
    }
};

export default FileField;