import axios from "axios";
import { store } from "../../redux/store";
import jwt_decode from "jwt-decode";

const Axios = axios.create({
    baseURL: process.env.REACT_APP_URL_API,
    timeout: 10000,
    credentials: "same-origin",
    headers: {
        "Content-Type": "application/json",
    },
});

// Set the AUTH token for any request
Axios.interceptors.request.use(function (config) {
    let token = store.getState().auth.token;

    if (token) {
        let decode = jwt_decode(token);
        let authorization = decode.authorization_token;
        config.headers.Authorization = authorization
            ? `Bearer ${authorization}`
            : "";
    }

    return config;
});

Axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 401) {
            localStorage.removeItem("persist:root");
            window.location.reload(false);
        }

        return Promise.reject(error);
    }
);

export default Axios;
