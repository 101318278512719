import React from "react";

const TextField = ({ index, field, handleChange, readOnlyForm }) => {

    /**
     * Render the text field input
     */
    return (
        <div className="p-2">
            <label htmlFor={field.id} className={`form-label fw-bolder m-2 ${field.required ? "required" : ""}`}>{field.label}</label>
            <input
                className="form-control"
                type="text"
                id={field.id}
                name={field.name}
                defaultValue={field.value}
                onChange={e => handleChange(index, e)}
                readOnly={readOnlyForm}
            />
        </div>
    );
};

export default TextField;