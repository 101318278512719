import NiceModal, {bootstrapDialog, useModal} from "@ebay/nice-modal-react";
import BootstrapModal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React, { useRef  } from "react";
import ProfileForm from "./";
import {useTranslation} from "react-i18next";

const ProfileFormModal = NiceModal.create(() => {

    const {t} = useTranslation();
    const modal = useModal();
    const ProfileFormRef = useRef();

    return (
        <BootstrapModal {...bootstrapDialog(modal)} size="md">
            <BootstrapModal.Header closeButton>
                <BootstrapModal.Title className="glow-dark-blue-color glow-modal-title">{t("modificationduprofil")}</BootstrapModal.Title>
            </BootstrapModal.Header>

            <BootstrapModal.Body className="overflow-auto max-h-750px">
                <ProfileForm ref={ProfileFormRef} modal={modal} />
            </BootstrapModal.Body>

            <BootstrapModal.Footer>
                <Button onClick={() => ProfileFormRef.current.handleSubmitForm()} className="btn btn-success btn-sm">{t("valider")}</Button>
                <Button onClick={modal.hide} className="btn btn-secondary btn-sm">{t("fermer")}</Button>
            </BootstrapModal.Footer>
        </BootstrapModal>
    );
});

export default ProfileFormModal;