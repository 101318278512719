import React, {useEffect, useMemo, useState} from 'react';
import {toast} from "react-toastify";
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { Loader } from "../../components";
import JobApi from "../../services/Api/JobApi";
import FilterHelper from "../../helpers/filter";
import Filter from "../../_tools/Datatable/Filter";
import {useTranslation} from "react-i18next";
import InternalJobListActions from "./InternalJobListActions";

const InternalJobList = () => {

    const { t } = useTranslation();

    const filterHelper = new FilterHelper();
    const jobApi = new JobApi();

    const [loading, setLoading] = useState(true);
    const [jobs, setJobs] = useState([]);
    const [filterText, setFilterText] = useState('');

    const getInternalJobs = async () => {
        let response = await jobApi.getInternalJobs();
        if (response.success) {
            setJobs(response.jobs);
            setLoading(false);
        } else {
            toast.error(t(response.message));
        }
    }

    const columns = [
        {
            name: t('titreduposte'),
            sortable: true,
            wrap: true,
            selector: 'title'
        },
        {
            name: t('client'),
            sortable: true,
            wrap: true,
            selector: 'client'
        },
        {
            name: t('publication'),
            sortable: true,
            wrap: true,
            selector: 'date'
        },
        {
            name: t('lieu'),
            sortable: true,
            wrap: true,
            selector: 'address'
        },
        {
            name: t(""),
            sortable: true,
            wrap: true,
            cell: (row) => <InternalJobListActions row={row} />,
        },
    ];

    const dataTableConverter = (data) => {
        let dataTable = [];

        if (data) {
            data.map((value, index) => {
                dataTable.push({
                    key: index + Math.random(),
                    index: index,
                    id: value.id,
                    demandenum: value.demandenum,
                    date: moment(value.date).format("YYYY-MM-DD"),
                    title: value.title,
                    client: value.client,
                    address: value.address,
                    company: value.company,
                    languages: value.languages,
                    diplomas: value.diplomas,
                    assets: value.assets,
                    skills: value.skills,
                    sectors: value.sectors,
                    jobTypes: value.job_types,
                    territories: value.territories,
                    permits: value.permits,
                    benefits: value.benefits,
                    description: value.description,
                    salaryMinimum: value.salary_minimum,
                    salaryMaximum: value.salary_maximum,
                    hasForm: value.has_form,
                    formId: value.form_id,
                    formData: value.form_data,
                })
            })
        }
        return dataTable;
    }

    const subHeaderFilterComponent = useMemo(() => {
        return <Filter onFilter={e => setFilterText(e.target.value)} filterText={filterText}/>;
    }, [filterText]);

    useEffect(() => {
        getInternalJobs();
    }, [])

    if(jobs && !loading) {
        return (
            <DataTable
                columns={columns}
                noDataComponent={t("aucunpostetrouves")}
                data={filterHelper.filteredItems(dataTableConverter(jobs), filterText)}
                keyField="key"
                pagination
                noHeader
                subHeader
                highlightOnHover
                subHeaderComponent={subHeaderFilterComponent}
                paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                paginationComponentOptions={{
                    rowsPerPageText: t('lignesparpage'),
                    rangeSeparatorText: t('sur'),
                    noRowsPerPage: false,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: 'All'
                }}
            />
        )
    }else{
        return (
            <Loader/>
        );
    }
    
}

export default InternalJobList;
