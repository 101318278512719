import Axios from "./AxioConfig";

class AuthApi {

    login(data) {
        return Axios.post('/api/login', data);
    }

    logout() {
        return Axios.get('/api/logout').then((response => {
            return response.data;
        }));
    }

    resetPassword(credentials) {
        return Axios.post('/api/reset-password', credentials).then((response => {
            return response.data;
        }));
    }

    forgotPassword(credentials) {
        return Axios.post('/api/forgot-password', credentials).then((response => {
            return response.data;
        }));
    }

    refreshToken(token) {
        if(token !== null) {
            const data = {
                token:token
            };
            return Axios.post('/api/refresh-token', data);
        }
        return false;
    }

    access() {
        return Axios.get('/api/access').then((response => {
            return response.data;
        }));
    }

    checkResetPasswordToken(token) {
        return Axios.post('/api/reset-password/check-token', {token}).then((response => {
            return response.data;
        }));
    }

}

export default AuthApi