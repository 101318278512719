import { useState } from "react";
import Countdown from "react-countdown";
import { Modal, Button } from "react-bootstrap";
import useIdle from "./";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { AuthApi } from "services";
import { useDispatch, useSelector } from "react-redux";
import { clearAuth, setToken } from "redux/slices/AuthSlice";

const IdleTimeoutModal = () => {
    const { t } = useTranslation();
    const authApi = new AuthApi();
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const auth = useSelector((state) => state.auth);
    const { token } = auth;

    const handleIdle = () => {
        setOpenModal(true);
    };

    const idleTime = 1080; //18 min in s
    const promptTimeBeforeIdle = 120; // 2 min in s

    const countDowntime = promptTimeBeforeIdle;

    const { idleTimer } = useIdle({
        onIdle: handleIdle,
        idleTime: idleTime,
        promptTimeBeforeIdle: promptTimeBeforeIdle,
    });

    const stay = () => {
        refreshToken();
        setOpenModal(false);
        idleTimer.reset();
    };

    const handleLogout = () => {
        setOpenModal(false);
        localStorage.removeItem("persist:root");
        window.location.reload(false);
    };

    const refreshToken = async () => {
        let response = await authApi.refreshToken(token);
        if (response && response.data) {
            if (response.data.success) {
                dispatch(setToken({ token: response.data.token }));
            }
        } else {
            dispatch(clearAuth());
        }
    };

    return (
        <Modal show={openModal} onHide={stay}>
            <Modal.Header closeButton>
                <Modal.Title className="glow-dark-blue-color glow-modal-title">
                    {t("findesession")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {parse(
                    t("votresessionestsurlepointdexpirercarvousavezeteinactif")
                )}
                <br></br>
                {t("elleexpireradans")} :{" "}
                <Countdown
                    zeroPadTime={2}
                    date={Date.now() + 1000 * countDowntime}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    className="btn btn-secondary btn-sm"
                    onClick={handleLogout}
                >
                    {t("sedeconnecter")}
                </Button>
                <Button className="btn btn-success btn-sm" onClick={stay}>
                    {t("resterconnecte")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default IdleTimeoutModal;
