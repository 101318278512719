import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { Loader } from "components";
import { CandidateApi } from "services";
import FilterHelper from "helpers/filter";
import Filter from "_tools/Datatable/Filter";
import { useTranslation } from "react-i18next";
import ConversationsListActions from "./ConversationsListActions";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import { useDispatch, useSelector } from "react-redux";
import { activeItem } from "redux/slices/MenuSlice";

const ConversationsList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const filterHelper = new FilterHelper();
    const candidateApi = new CandidateApi();

    const [loading, setLoading] = useState(true);
    const [conversations, setConversations] = useState([]);
    const [filterText, setFilterText] = useState("");

    const getEmployeeConversations = async () => {
        const response = await candidateApi.getMandatsConversationsList();
        if (response.success) {
            setConversations(response.data.conversations);
            setLoading(false);
        } else {
            toast.error(t(response.message));
        }
    };

    const columns = [
        {
            name: t("client"),
            sortable: true,
            wrap: true,
            selector: "clientName",
        },
        {
            name: t("gestionnaire"),
            sortable: true,
            wrap: true,
            selector: "managerName",
        },
        {
            name: t("datedumessage"),
            sortable: true,
            wrap: true,
            selector: "messageDate",
        },
        {
            name: t("derniermessage"),
            sortable: true,
            wrap: true,
            selector: "lastMessage",
        },
        {
            name: t(""),
            sortable: true,
            wrap: true,
            cell: (row) => <ConversationsListActions row={row} callBack={getEmployeeConversations} />,
        },
    ];

    const subHeaderFilterComponent = useMemo(() => {
        return (
            <Filter
                onFilter={(e) => setFilterText(e.target.value)}
                filterText={filterText}
            />
        );
    }, [filterText]);

    const dataTableConverter = (data) => {
        let dataTable = [];
        if (data) {
            data.map((value, index) => {
                let timestamp = new Date().getUTCMilliseconds();

                let preview = '';
                if(value.last_message) {
                    preview = value.last_message.replace(/(<([^>]+)>)/gi, " ");
                    preview = parse(DOMPurify.sanitize(preview));
                    preview = preview.slice(0, 100);
                    preview += preview.length > 100 ? "..." : "";
                }

                dataTable.push({
                    key: index + "_" + timestamp,
                    index: index,
                    conversationId: value.employee_conversation_id,
                    mandatId: value.mandat_id,
                    clientName: value.client_name,
                    conversationDate: value.conversation_date,
                    managerName: value.manager_name,
                    managerEmail: value.manager_email,
                    messageDate: value.message_date,
                    lastMessage: preview,
                    messagesNoRead: value.messages_no_read,
                });
            });
        }
        return dataTable;
    };

    useEffect(() => {
        getEmployeeConversations();
        dispatch(activeItem({ openItem: ["conversations"] }));
    }, []);

    if (conversations && !loading) {
        return (
            <DataTable
                columns={columns}
                noDataComponent={t("aucuneconversationtrouvees")}
                data={filterHelper.filteredItems(
                    dataTableConverter(conversations),
                    filterText
                )}
                keyField="key"
                pagination
                noHeader
                subHeader
                highlightOnHover
                subHeaderComponent={subHeaderFilterComponent}
                paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                paginationComponentOptions={{
                    rowsPerPageText: t("lignesparpage"),
                    rangeSeparatorText: t("sur"),
                    noRowsPerPage: false,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: "All",
                }}
            />
        );
    } else {
        return <Loader />;
    }
};

export default ConversationsList;
