import React, { useEffect, useState } from "react";
import CandidateApi from "../../services/Api/CandidateApi";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import { Loader } from "../../components";

const DiplomasList = () => {
    const { t } = useTranslation();
    const candidateApi = new CandidateApi();

    const [loading, setLoading] = useState(true);
    const [diplomas, setDiplomas] = useState([]);

    const getDiplomasLicenses = async () => {
        const response = await candidateApi.getDiplomas();
        if (response.success) {
            setDiplomas(response.data.diplomas);
            setLoading(false);
        } else {
            toast.error(t(response.message));
        }
    };

    const columns = [
        {
            name: t("diplome"),
            sortable: true,
            wrap: true,
            selector: "diploma",
        },
        {
            name: t("institution"),
            sortable: true,
            wrap: true,
            selector: "institution",
        },
        {
            name: t("date"),
            sortable: true,
            wrap: true,
            selector: "date",
        },
        {
            name: t("pays"),
            sortable: true,
            wrap: true,
            selector: "country",
        },
    ];

    const dataTableConverter = (data) => {
        let dataTable = [];

        if (data) {
            data.map((value, index) => {
                dataTable.push({
                    key: index + Math.random(),
                    index: index,
                    id: value.id,
                    diploma: value.diploma.description,
                    institution: value.institution,
                    date: value.date,
                    country: value.country.name,
                });
            });
        }
        return dataTable;
    };

    useEffect(() => {
        getDiplomasLicenses();
    }, []);

    if (diplomas && !loading) {
        return (
            <DataTable
                columns={columns}
                noDataComponent={t("aucundiplomestrouves")}
                data={dataTableConverter(diplomas)}
                keyField="key"
                noHeader
                highlightOnHover
            />
        );
    } else {
        return <Loader />;
    }
};

export default DiplomasList;
