import {
    Box,
    CardMedia,
    Typography,
    Grid,
} from "@mui/material";
import MainCard from "components/Cards/MainCard";
import Logo from "assets/images/glowinthecloud.png";

const GlowVersion = () => (
    <Box>
        <Grid container direction="row" display="flex" justifyContent="space-around" alignItems="center" p={2}>
            <Grid>
                <CardMedia component="img" image={Logo} sx={{ width: 75 }} />
            </Grid>
            <Grid>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: "#0f4585" }}>V 2.0 &copy;</Typography>
            </Grid>
        </Grid>
    </Box>
);

export default GlowVersion;
