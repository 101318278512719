import Axios from "./AxioConfig";

class CandidateApi {

    getProfile() {
        return Axios.get('/api/candidate/profile').then((response => {
            return response.data;
        }));
    }

    getExperiences() {
        return Axios.get('/api/candidate/jobs-experience').then((response => {
            return response.data;
        }));
    }

    getDiplomas() {
        return Axios.get('/api/candidate/diplomas').then((response => {
            return response.data;
        }));
    }

    getLanguages() {
        return Axios.get('/api/candidate/languages').then((response => {
            return response.data;
        }));
    }

    getInterviews() {
        return Axios.get('/api/candidate/interviews').then((response => {
            return response.data;
        }));
    }

    getSkills() {
        return Axios.get('/api/candidate/skills').then((response => {
            return response.data;
        }));
    }

    getCurrentMandates() {
        return Axios.get('/api/candidate/mandates').then((response => {
            return response.data;
        }));
    }

    getPastMandates() {
        return Axios.get('/api/candidate/past-mandates').then((response => {
            return response.data;
        }));
    }

    getForms() {
        return Axios.get('/api/candidate/forms').then((response => {
            return response.data;
        }));
    }

    getDocuments(type) {
        return Axios.get('/api/candidate/documents', {params: {type}}).then((response => {
            return response.data;
        }));
    }

    getPositionHistory() {
        return Axios.get('/api/candidate/position-history').then((response => {
            return response.data;
        }));
    }

    getFormations() {
        return Axios.get('/api/candidate/formations').then((response => {
            return response.data;
        }));
    }

    getEvaluations() {
        return Axios.get('/api/candidate/evaluations').then((response => {
            return response.data;
        }));
    }

    getResponseFormFields(formResponseId) {
        return Axios.get('/api/candidate/form-response/fields', {params: {formResponseId}}).then((response => {
            return response.data;
        }));
    }

    addDiploma(diploma) {
        return Axios.post('/api/candidate/diploma/add', diploma).then((response => {
            return response.data;
        }));
    }

    addExperience(experience) {
        return Axios.post('/api/candidate/experience/add', experience).then((response => {
            return response.data;
        }));
    }

    updateProfile(profile) {
        return Axios.patch('/api/candidate/profile/update', profile).then((response => {
            return response.data;
        }));
    }
    
    getMandatsConversationsList() {
        return Axios.get('/api/candidate/mandates/conversations/list').then((response => {
            return response.data;
        }));
    }

    getMandatConversationMessages(conversationId, mandatId) {
        return Axios.get(`/api/candidate/mandate/${mandatId}/conversation/${conversationId}/messages`).then((response => {
            return response.data;
        }));
    }

    saveConversationMessage(data) {
        return Axios.post(`/api/candidate/mandate/${data.mandatId}/conversation/${data.conversationId}/message/save`, data).then((response => {
            return response.data;
        }));
    }

    updateTrainingSessionPresent(data) {
        return Axios.patch(`/api/candidate/training-session/${data.id}/present`, data).then((response => {
            return response.data;
        }));
    }
}

export default CandidateApi