import {createSlice} from "@reduxjs/toolkit";

export const HeaderSlice = createSlice({
    name: "header",
    initialState: {
        conversationsCounter: 0,
    },
    reducers: {
        setConversationsCounter: (state, action) => {
            state.conversationsCounter = action.payload;
        },
        decrementConversationsCounter: (state, action) => {
            if(state.conversationsCounter > 0) {
                state.conversationsCounter--;
            }
        },
        clearHeader: (state) => {
            state.conversationsCounter = 0;
        },
    },
});

export const {setConversationsCounter, decrementConversationsCounter, clearHeader} = HeaderSlice.actions;

export default HeaderSlice.reducer;
