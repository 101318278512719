import NiceModal, {bootstrapDialog, useModal} from "@ebay/nice-modal-react";
import BootstrapModal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React from "react";
import FileViewer from "./FileViewer";
import {useTranslation} from "react-i18next";
import { Link } from "@mui/material";

const ConversationDetailModal = NiceModal.create(({data}) => {

    const {t} = useTranslation();
    const modal = useModal();

    const extensions = ['pdf','doc', 'docx', 'xls', 'xlsx', 'jpg', 'jpeg', 'png', 'txt'];

    let documentName = data.name;
    if(documentName) {
        documentName = documentName.slice(0, 50);
        documentName += documentName.length > 50 ? "..." : "";
    }

    if (extensions.includes(data.extension)) {
        return (
            <BootstrapModal {...bootstrapDialog(modal)} size="lg" backdrop="static">
                <BootstrapModal.Header closeButton>
                    <BootstrapModal.Title className="glow-dark-blue-color glow-modal-title">{t('document')} : {documentName}</BootstrapModal.Title>
                </BootstrapModal.Header>
    
                <BootstrapModal.Body className="overflow-auto max-h-750px" style={{ bgColor: "#EEF0F8"}}>
                    <FileViewer fileObject={data} />
                </BootstrapModal.Body>
    
                <BootstrapModal.Footer>

                    <Link
                        href={data.downloadUrl}
                        download
                        style={{ textDecoration: "none" }}
                    >
                        <Button className="btn btn-success btn-sm m-1">{t("telecharger")}</Button>
                    </Link>

                    <Button onClick={() => {modal.hide()}} className="btn btn-secondary btn-sm m-1">{t("fermer")}</Button>
                </BootstrapModal.Footer>
            </BootstrapModal>
        );
    }else{
        return (<>{t('extensiondefichiernonsupporte')}</>)
    }
    
});

export default ConversationDetailModal;