
import React from 'react';
import GoalsEvaluations from "./Tables/GoalsEvaluations";
import PerformancesEvaluations from "./Tables/PerformancesEvaluations";
import SkillsEvaluations from "./Tables/SkillsEvaluations";
import {Grid} from "@mui/material";
import {MainCard} from "../../components";
import {useTranslation} from "react-i18next";

const EvaluationsDetails = ({evaluations}) => {
    const {t} = useTranslation();

    return (
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            {evaluations && evaluations.goals.length > 0 &&
            <Grid item xs={12} md={12} lg={12}>
                <MainCard title={t('objectifs')}>
                    <GoalsEvaluations data={evaluations.goals}/>
                </MainCard>
            </Grid>
            }
            {evaluations && evaluations.performances.length > 0 &&
            <Grid item xs={12} md={12} lg={12}>
                <MainCard title={t('performances')}>
                    <PerformancesEvaluations data={evaluations.performances}/>
                </MainCard>
            </Grid>
            }
            {evaluations && evaluations.skills.length > 0 &&
            <Grid item xs={12} md={12} lg={12}>
                <MainCard title={t('competences')}>
                    <SkillsEvaluations data={evaluations.skills}/>
                </MainCard>
            </Grid>
            }
        </Grid>
    )
}

export default EvaluationsDetails;
