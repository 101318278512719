import Axios from "./AxioConfig";

class CompanyApi {

    getCompany() {
        return Axios.get('/api/company').then((response => {
            return response.data;
        }));
    }
}

export default CompanyApi;