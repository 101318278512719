import React, {useEffect, useMemo, useState, Suspense } from 'react';
import {toast} from "react-toastify";
import DataTable from 'react-data-table-component';
import moment from "moment";
import parse from "html-react-parser";
import { Loader } from "../../components";
import CandidateApi from "../../services/Api/CandidateApi";
import FilterHelper from "../../helpers/filter";
import Filter from "../../_tools/Datatable/Filter";
import FormsListActions from "./FormsListActions";
import {useTranslation} from "react-i18next";

const FormsList = () => {

    const {t} = useTranslation();

    const filterHelper = new FilterHelper();
    const candidateApi = new CandidateApi();

    const [loading, setLoading] = useState(true);
    const [forms, setForms] = useState([]);
    const [filterText, setFilterText] = useState('');

    const getForms = async () => {
        const response = await candidateApi.getForms();
        if (response.success) {
            setForms(response.forms);
            setLoading(false);
        } else {
            toast.error(t(response.message));
        }
    }

    const columns = [
        {
            name: t('datedenvoi'),
            sortable: true,
            wrap: true,
            center: true,
            selector: 'createdOn'
        },
        {
            name: parse(t('datedexpiration')),
            sortable: true,
            wrap: true,
            center: true,
            selector: 'expiryDate'
        },
        {
            name: parse(t('datedesoumission')),
            sortable: true,
            wrap: true,
            center: true,
            cell: (row) => row.hasResponse ? row.submitDate : '-',
        },
        {
            name: t('formulaire'),
            sortable: true,
            wrap: true,
            selector: 'formName'
        },
        {
            name: t('statut'),
            sortable: true,
            selector: 'statusLabel'
        },
        {
            name: t(''),
            sortable: true,
            wrap: true,
            cell: (row) => <FormsListActions row={row}/>
        },
    ];

    const subHeaderFilterComponent = useMemo(() => {
        return <Filter onFilter={e => setFilterText(e.target.value)} filterText={filterText}/>;
    }, [filterText]);

    /**
     * Format data for datatable
     */
    const dataTableConverter = (data) => {

        let dataTable = [];

        if (data) {

            data.map((value, index) => {

                // Get an array of recruiters name
                let recruiters = [];
                value.recruiters.map((value, index) => {
                    if (value.id) {
                        recruiters.push(value.name);
                    }
                })

                dataTable.push({
                    key: index + value.created_on + Math.random(),
                    index: index,
                    formName: value.formulaire.name,
                    hasResponse: value.hasResponse,
                    formResponseId: value.formulaire_reponse_id,
                    recruiters: recruiters.toString(),
                    expiryDate: moment(value.expiry_date).format("L"),
                    submitDate: moment(value.submit_date).format("L"),
                    createdOn: moment(value.created_on).format("L"),
                    isEditable: value.is_editable,
                    statusLabel: t(value.statusLabel),
                    urlForm: value.urlForm,
                })
            })
        }
        return dataTable;
    }

    useEffect(() => {
        getForms();
    }, [])

    if(forms && !loading) {
        return (
            <DataTable
                    columns={columns}
                    noDataComponent={t("aucunformulairestrouves")}
                    data={filterHelper.filteredItems(dataTableConverter(forms), filterText)}
                    keyField="key"
                    pagination
                    noHeader
                    subHeader
                    highlightOnHover
                    subHeaderComponent={subHeaderFilterComponent}
                    paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                    paginationComponentOptions={{
                        rowsPerPageText: t('lignesparpage'),
                        rangeSeparatorText: t('sur'),
                        noRowsPerPage: false,
                        selectAllRowsItem: false,
                        selectAllRowsItemText: 'All'
                    }}
                />
        )
    }else{
        return (
            <Loader/>
        );
    }
    
}

export default FormsList;
