import React, { useState } from "react";
import { toast } from "react-toastify";
import { Link as RouterLink, Link } from "react-router-dom";
import { AuthApi, CandidateApi, CompanyApi, EmployeeApi } from "services";
import { useDispatch } from "react-redux";
import {
    setAccess,
    setToken,
    setUser,
    setCompany,
} from "redux/slices/AuthSlice";
import { useTranslation } from "react-i18next";
import {
    Button,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography,
    MenuItem,
    Select,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { useTheme } from "@mui/material/styles";

const Login = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const employeeApi = new EmployeeApi();
    const authApi = new AuthApi();
    const candidateApi = new CandidateApi();
    const companyApi = new CompanyApi();

    const dispatch = useDispatch();

    const [showPassword, setShowPassword] = useState(false);
    const [cieData, setCieData] = useState([]);
    const [showLogin, setShowLogin] = useState(true);
    const [showCieChoice, setShowCieChoice] = useState(false);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [selectedCie, setSelectedCie] = useState("");

    const handleSelectedCieChange = (e, setFieldValue) => {
        setFieldValue("cie", e.target.value);
        setSelectedCie(e.target.value);
    };

    const handleEmailChange = (e, setFieldValue) => {
        setFieldValue("email", e.target.value);
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e, setFieldValue) => {
        setFieldValue("password", e.target.value);
        setPassword(e.target.value);
    };

    const checkEmployeeCie = async () => {
        let response = await employeeApi.getEmployeeCie(email);
        if (response.success) {
            if (response.total > 1) {
                setShowLogin(false);
                setShowCieChoice(true);
                setCieData(response.cieData);
            } else {
                const lang = localStorage.getItem('i18nextLng');
                const data = { email: email, password: password, lang: lang };
                handleLogin(data);
            }
        } else {
            toast.error(t(response.message));
        }
    };

    const handleLogin = async (data) => {
        let response = await authApi.login(data);
        if (response.data.success) {
            getAccess();
            getProfile();
            getCompany();

            dispatch(setToken({ token: response.data.token }));
        } else {
            toast.error(t(response.data.message));
        }
    };

    const getAccess = async () => {
        let allowedModule = [];
        let allowedSubmodule = [];
        let response = await authApi.access();
        if (response.success) {
            response.access.forEach((element) => {
                allowedModule.push(element.id);
                element.submodules.forEach((item) => {
                    allowedSubmodule.push(item.id);
                });
            });
            dispatch(
                setAccess({
                    access: {
                        module: allowedModule,
                        submodules: allowedSubmodule,
                    },
                })
            );
        }
    };

    const getProfile = async () => {
        let response = await candidateApi.getProfile();
        if (response.success) {
            dispatch(setUser({ user: response.profile }));
        } else {
            toast.error(t(response.message));
        }
    };

    const getCompany = async () => {
        let response = await companyApi.getCompany();
        if (response.success) {
            dispatch(setCompany({ company: response.company }));
        } else {
            toast.error(t(response.message));
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="baseline"
                    sx={{ mb: { xs: -0.5, sm: 0.5 } }}
                >
                    <Typography variant="h3" align="center">
                        {t("connexionauportail")}
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                {showLogin && (
                    <Formik
                        initialValues={{
                            email: "",
                            password: "",
                        }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string()
                                .email(t("entreruncourrielvalide"))
                                .max(255)
                                .required(t("courrielobligatoire")),
                            password: Yup.string()
                                .max(255)
                                .required(t("motdepasseobligatoire")),
                        })}
                        onSubmit={(values) => {
                            checkEmployeeCie();
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            setFieldValue,
                        }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="email-login">
                                                {t("courriel")}
                                            </InputLabel>
                                            <OutlinedInput
                                                id="email-login"
                                                type="email"
                                                value={values.email}
                                                name="email"
                                                onBlur={handleBlur}
                                                onChange={(e) =>
                                                    handleEmailChange(
                                                        e,
                                                        setFieldValue
                                                    )
                                                }
                                                fullWidth
                                                error={Boolean(
                                                    touched.email &&
                                                        errors.email
                                                )}
                                            />
                                            {touched.email && errors.email && (
                                                <FormHelperText
                                                    error
                                                    id="standard-weight-helper-text-email-login"
                                                >
                                                    {errors.email}
                                                </FormHelperText>
                                            )}
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="password-login">
                                                {t("motdepasse")}
                                            </InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                error={Boolean(
                                                    touched.password &&
                                                        errors.password
                                                )}
                                                id="password-login"
                                                type={
                                                    showPassword
                                                        ? "text"
                                                        : "password"
                                                }
                                                value={values.password}
                                                name="password"
                                                onBlur={handleBlur}
                                                onChange={(e) =>
                                                    handlePasswordChange(
                                                        e,
                                                        setFieldValue
                                                    )
                                                }
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={
                                                                handleClickShowPassword
                                                            }
                                                            onMouseDown={
                                                                handleMouseDownPassword
                                                            }
                                                            edge="end"
                                                            size="large"
                                                        >
                                                            {showPassword ? (
                                                                <EyeOutlined />
                                                            ) : (
                                                                <EyeInvisibleOutlined />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                            {touched.password &&
                                                errors.password && (
                                                    <FormHelperText
                                                        error
                                                        id="standard-weight-helper-text-password-login"
                                                    >
                                                        {errors.password}
                                                    </FormHelperText>
                                                )}
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sx={{ mt: -1 }}>
                                        <Stack
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <Link
                                                variant="h6"
                                                component={RouterLink}
                                                to="/forgot-password"
                                                color="text.primary"
                                                style={{
                                                    textDecoration: "none",
                                                    color: "#009ef7",
                                                    fontWeight: "600",
                                                    fontSize: "15px",
                                                }}
                                            >
                                                {t("motdepasseoublie")} ?
                                            </Link>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            disableElevation
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            sx={{
                                                backgroundColor:
                                                    theme.palette.glow.primary,
                                            }}
                                        >
                                            {t("ouvrirunesession")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                )}

                {showCieChoice && (
                    <Formik
                        initialValues={{
                            cie: "",
                        }}
                        validationSchema={Yup.object().shape({
                            cie: Yup.string().required(
                                t("veuillezselectionerunecompagnie")
                            ),
                        })}
                        onSubmit={(values) => {
                            if (selectedCie) {
                                const lang = localStorage.getItem('i18nextLng');
                                const data = {
                                    email: email,
                                    password: password,
                                    cie: selectedCie,
                                    lang: lang
                                };
                                handleLogin(data);
                            } else {
                                toast.error(
                                    t("veuillezselectionerunecompagnie")
                                );
                            }
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            setFieldValue,
                        }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Stack spacing={1}>
                                                <InputLabel>
                                                    {t("choixdelacompagnie")}
                                                </InputLabel>
                                                <Select
                                                    id="cie-login"
                                                    name="cie"
                                                    fullWidth
                                                    error={Boolean(
                                                        touched.cie &&
                                                            errors.cie
                                                    )}
                                                    onBlur={handleBlur}
                                                    value={values.cie}
                                                    onChange={(e) =>
                                                        handleSelectedCieChange(
                                                            e,
                                                            setFieldValue
                                                        )
                                                    }
                                                >
                                                    {cieData.map(
                                                        (
                                                            { cieSeq, cieName },
                                                            index
                                                        ) => (
                                                            <MenuItem
                                                                key={index}
                                                                value={cieSeq}
                                                            >
                                                                {cieName}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                                {touched.cie && errors.cie && (
                                                    <FormHelperText
                                                        error
                                                        id="standard-weight-helper-text-cie-login"
                                                    >
                                                        {errors.cie}
                                                    </FormHelperText>
                                                )}
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                disableElevation
                                                fullWidth
                                                // disabled={isSubmitting}
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                sx={{
                                                    backgroundColor:
                                                        theme.palette.glow
                                                            .primary,
                                                }}
                                            >
                                                {t("valider")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                )}
            </Grid>
        </Grid>
    );
};

export default Login;
