import React, {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
    useRef
} from "react";
import { useTranslation } from "react-i18next";
import {
    Grid,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
} from "@mui/material";
import { CandidateApi } from "services";
import { toast } from "react-toastify";
import { Loader } from "components";
import { useDispatch } from "react-redux";
import { decrementConversationsCounter } from "redux/slices/HeaderSlice";

const ConversationDetail = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const candidateApi = new CandidateApi();
    const scrollRef = useRef(null);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);
    const [messagesList, setMessagesList] = useState([]);

    const getConversation = async () => {

        const response = await candidateApi.getMandatConversationMessages(props.data.conversationId, props.data.mandatId);
        if (response.success) {
            setLoading(false);
            setMessagesList(response.data);
        } else {
            toast.error(t(response.message));
        }
    };

    useImperativeHandle(ref, () => ({
        handleExpandedSend() {
            saveConversationMessage();
        },
    }));

    const saveConversationMessage = async () => {

        if(props.message == ""){
            toast.error(t('lemessagenepeutetrevide'));
            return false;
        }

        const data = {message: props.message, conversationId: props.data.conversationId, mandatId: props.data.mandatId, managerEmail: props.data.managerEmail};
        const response = await candidateApi.saveConversationMessage(data);

        if (response.success) {
            props.data.conversationId = response.data.conversationId
            getConversation();
        } else {
            toast.error(t(response.message));
        }
    };

    useEffect(() => {
        getConversation();
        dispatch(decrementConversationsCounter());
    }, []);

    useEffect(() => {
        if (scrollRef.current) {
          scrollRef.current.scrollIntoView({ behaviour: "smooth" });
        }
      }, [messagesList]);

    if (messagesList && !loading) {
        return (
            <>
                <Grid
                    container
                    sx={{
                        width: "100%",
                        height: "70vh",
                    }}
                >
                    <Grid item xs={12}>
                        <List sx={{ height: "68vh", overflowY: "auto" }}>
                            {messagesList.map((item, index) => {
                                return (
                                    <>
                                        <ListItem
                                            key={index}
                                            sx={{
                                                width: "fit-content",
                                                marginRight: item.is_manager
                                                    ? "auto"
                                                    : "",
                                                marginLeft: item.is_manager
                                                    ? ""
                                                    : "auto",
                                            }}
                                        >
                                            {item.is_manager && (
                                                <ListItemAvatar
                                                    sx={{
                                                        textAlign: "right",
                                                    }}
                                                >
                                                    <Avatar
                                                        alt="manager avatar"
                                                        src={item.avatar}
                                                        sx={{
                                                            width: 38,
                                                            height: 38,
                                                        }}
                                                    />
                                                </ListItemAvatar>
                                            )}
                                            <Grid
                                                container
                                                sx={{
                                                    padding: "10px",
                                                    bgcolor: item.is_manager
                                                        ? "#eef0f8"
                                                        : "#e6f7ff",
                                                    borderRadius: "10px",
                                                }}
                                            >
                                                <Grid item xs={12}>
                                                    <ListItemText
                                                        secondaryTypographyProps={{
                                                            fontSize: "12px",
                                                        }}
                                                        align="left"
                                                        secondary={
                                                            item.is_manager ? (
                                                                <div>
                                                                    <strong>
                                                                        {
                                                                            item.participant_name
                                                                        }
                                                                    </strong>
                                                                    <span className="ms-2">
                                                                        {
                                                                            item.message_date
                                                                        }
                                                                    </span>
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    {
                                                                        item.message_date
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                    ></ListItemText>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <ListItemText
                                                        primaryTypographyProps={{
                                                            fontSize: "14px",
                                                        }}
                                                        align="left"
                                                        primary={item.message}
                                                    ></ListItemText>
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                    </>
                                );
                            })}
                            <div ref={scrollRef} />
                        </List>
                    </Grid>
                </Grid>
            </>
        );
    } else {
        return <Loader />;
    }
});

export default ConversationDetail;
