import React, {useEffect, useMemo, useState} from 'react';
import CandidateApi from "services/Api/CandidateApi";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import FilterHelper from "helpers/filter";
import Filter from "_tools/Datatable/Filter";
import DataTable from "react-data-table-component";
import { Loader } from "components";

const PositionHistoryList = () => {

    const { t } = useTranslation();

    const candidateApi = new CandidateApi();
    const filterHelper = new FilterHelper();

    const [loading, setLoading] = useState(true);
    const [positionHistory, setPositionHistory] = useState([]);
    const [filterText, setFilterText] = useState('');

    const getPositionHistory = async () => {
        const response = await candidateApi.getPositionHistory();
        if (response.success) {
            setPositionHistory(response.positionHistory);
            setLoading(false);
        } else {
            toast.error(t(response.message));
        }
    };

    const subHeaderFilterComponent = useMemo(() => {
        return <Filter onFilter={e => setFilterText(e.target.value)} filterText={filterText}/>;
    }, [filterText]);

    const columns = [
        {
            name: t('titreduposte'),
            sortable: true,
            wrap: true,
            selector: 'position'
        },
        {
            name: t('client'),
            sortable: true,
            wrap: true,
            selector: 'client'
        },
        {
            name: t('date'),
            sortable: true,
            wrap: true,
            selector: 'date'
        },
        {
            name: t('statut'),
            center: true,
            selector: 'status'
        },
    ];

    useEffect(() => {
        getPositionHistory();
    }, []);

    /**
     * Format data for datatable
     */
    const dataTableConverter = (data) => {

        let dataTable = [];

        if (data) {

            data.map((value, index) => {

                dataTable.push({
                    key: index + value.id + Math.random(),
                    index: index,
                    client: value.client.name,
                    position: value.position,
                    date: value.date,
                    status: t(value.status),
                })
            })
        }
        return dataTable;
    }

    if(positionHistory && !loading) {
        return (
            <DataTable
                columns={columns}
                noDataComponent={t("aucunedonneestrouvees")}
                data={filterHelper.filteredItems(dataTableConverter(positionHistory), filterText)}
                keyField="key"
                pagination
                noHeader
                subHeader
                highlightOnHover
                subHeaderComponent={subHeaderFilterComponent}
                paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                paginationComponentOptions={{
                    rowsPerPageText: t('lignesparpage'),
                    rangeSeparatorText: t('sur'),
                    noRowsPerPage: false,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: 'All'
                }}
            />
        )
    }else{
        return (
            <Loader/>
        );
    }
    
}

export default PositionHistoryList;
