import React, {useEffect, useState} from 'react';
import {toast} from "react-toastify";
import AuthApi from "../../services/Api/AuthApi";
import { useTranslation } from 'react-i18next';
import {useNavigate, useParams} from "react-router-dom";
import {
    Button,
    FormHelperText,
    Grid, IconButton, InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography
} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import { useTheme } from '@mui/material/styles';

const ResetPassword = () => {
    let {token} = useParams();

    const authApi = new AuthApi();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useTheme();

    const [resetToken, setResetToken] = useState(token);
    const [showNewPassword, setShowNewPassword] = useState(true);
    const [errorMessage, setErrorMessage] = useState();
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);
    const [newPassword, setNewPassword]= useState('');
    const [repeatNewPassword, setRepeatNewPassword]= useState('');

    const handleClickShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };

    const handleClickShowRepeatPassword = () => {
        setShowRepeatPassword(!showRepeatPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleNewPasswordChange = (e, setFieldValue) => {
        setFieldValue('newpassword', e.target.value);
        setNewPassword(e.target.value);
    }

    const handleRepeatPasswordChange = (e, setFieldValue) => {
        setFieldValue('repeatpassword', e.target.value);
        setRepeatNewPassword(e.target.value);
    }

    const handleResetPassword = async () => {
        if (newPassword === repeatNewPassword) {
            const data = {newPassword: newPassword, repeatNewPassword: repeatNewPassword, token: token};
            const response = await authApi.resetPassword(data);
            if (response.success) {
                toast.success(t(response.message));
                setTimeout(() => {
                    navigate("/");
                }, 1500);

            } else {
                toast.error(t(response.message));
            }
        } else {
            toast.error(t('lesmotsdepassesnesontpasidentique'));
        }

    };

    const checkToken = async () => {
        const response = await authApi.checkResetPasswordToken(resetToken);
        if (response.success) {
            setShowNewPassword(true);
        } else {
            setShowNewPassword(false);
            setErrorMessage(t(response.message));
        }
    }

    useEffect(() => {
        checkToken();
    }, []);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Stack direction="row" justifyContent="center" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
                    <Typography variant="h3" align="center">{t('motdepasseoublie')}</Typography>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                {showNewPassword &&
                <Formik
                    initialValues={{
                        newpassword: '',
                        repeatpassword: '',
                    }}
                    validationSchema={Yup.object().shape({
                        newpassword: Yup.string().max(255).required(t('motdepasseobligatoire')),
                        repeatpassword: Yup.string().max(255).required(t('motdepassedeconfirmationobligatoire'))
                    })}
                    onSubmit={(values) => {
                        handleResetPassword();
                    }}
                >
                    {({
                          errors,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          touched,
                          values,
                          setFieldValue
                      }) => (
                        <form noValidate onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Stack spacing={1}>
                                        <InputLabel htmlFor="new-password">{t('nouveaumotdepasse')}</InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            error={Boolean(touched.newpassword && errors.newpassword)}
                                            id="new-password"
                                            type={showNewPassword ? 'text' : 'password'}
                                            value={values.newpassword}
                                            name="newpassword"
                                            onBlur={handleBlur}
                                            onChange={e => handleNewPasswordChange(e, setFieldValue)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowNewPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                        size="large"
                                                    >
                                                        {showNewPassword ? <EyeOutlined/> : <EyeInvisibleOutlined/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        {touched.newpassword && errors.newpassword && (
                                            <FormHelperText error id="standard-weight-helper-text-password-login">
                                                {errors.newpassword}
                                            </FormHelperText>
                                        )}
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack spacing={1}>
                                        <InputLabel htmlFor="repeat-password">{t('confirmerlemotdepasse')}</InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            error={Boolean(touched.repeatpassword && errors.repeatpassword)}
                                            id="repeat-password"
                                            type={showRepeatPassword ? 'text' : 'password'}
                                            value={values.repeatpassword}
                                            name="repeatpassword"
                                            onBlur={handleBlur}
                                            onChange={e => handleRepeatPasswordChange(e, setFieldValue)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowRepeatPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                        size="large"
                                                    >
                                                        {showRepeatPassword ? <EyeOutlined/> : <EyeInvisibleOutlined/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        {touched.repeatpassword && errors.repeatpassword && (
                                            <FormHelperText error id="standard-weight-helper-text-password-login">
                                                {errors.repeatpassword}
                                            </FormHelperText>
                                        )}
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        disableElevation
                                        // disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            backgroundColor: theme.palette.glow.primary,
                                        }}
                                    >
                                        {t('valider')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
                }

                {!showNewPassword &&
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Stack spacing={1}>
                            {errorMessage}
                        </Stack>
                    </Grid>
                </Grid>
                }
            </Grid>
        </Grid>
    )
}

export default ResetPassword;