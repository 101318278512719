import React from "react";
import { Navigate } from "react-router-dom";
import MainLayout from "../layout/MainLayout"
import { useSelector } from "react-redux";

const ProtectedRoutes = () => {

    const isAuth = Boolean(useSelector((state) => state.auth.token));

    return (isAuth ? <MainLayout/> : <Navigate to="/" replace/>)

};

export default ProtectedRoutes;
