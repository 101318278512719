import { CircularProgress, Grid } from "@mui/material";

const Loader = () => (
    <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        gap={2}
    >
        <CircularProgress />
    </Grid>
);

export default Loader;
