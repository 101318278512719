import React from 'react';
import EvaluationsDetails from './EvaluationsDetails';
import {useTranslation} from "react-i18next";
import NiceModal, {bootstrapDialog, useModal} from "@ebay/nice-modal-react";
import BootstrapModal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const EvaluationDetailsModal = NiceModal.create(({data}) => {

    const {t} = useTranslation();
    const modal = useModal();

    return (
        <BootstrapModal {...bootstrapDialog(modal)} size="lg">
            <BootstrapModal.Header closeButton>
                <BootstrapModal.Title className="glow-dark-blue-color glow-modal-title">{t("detailsdelevaluation")}</BootstrapModal.Title>
            </BootstrapModal.Header>

            <BootstrapModal.Body className="overflow-auto max-h-750px">
                <EvaluationsDetails evaluations={data} />
            </BootstrapModal.Body>

            <BootstrapModal.Footer>
            <Button onClick={modal.hide} className="btn btn-secondary btn-sm">{t("fermer")}</Button>
            </BootstrapModal.Footer>
        </BootstrapModal>
    );
});

export default EvaluationDetailsModal;
