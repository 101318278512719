import Axios from "./AxioConfig";

class TrainingApi {

    getTrainingFiles(id) {
        return Axios.get(`/api/training/${id}/files`).then((response => {
            return response.data;
        }));
    }
}

export default TrainingApi