import React, { useState, useEffect } from "react";
import { Grid, Typography, Divider, Button, Box } from "@mui/material";
import {
    faCalendarDay,
    faUserTie,
    faSuitcase,
    faMapMarkerAlt,
    faMagnifyingGlass,
    faClock,
    faGlobe,
    faLanguage,
    faGraduationCap,
    faStar,
    faIdCard,
    faPuzzlePiece,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import parse from "html-react-parser";
import DOMPurity from "dompurify";
import { JobApi } from "services";
import { toast } from "react-toastify";
import FormGenerator from "_tools/FormGenerator/Form";

const RenderInfo = ({ icon, info, iconStyle, title }) => {
    return (
        <Grid item xs={3} sm={3} md={3}>
            <Typography variant="body2" color="text.secondary">
                <FontAwesomeIcon
                    className="me-1"
                    icon={icon}
                    style={{ ...iconStyle }}
                />
                {title}
            </Typography>
            <span
                class="badge bg-glow-dark-blue-color"
                style={{ fontSize: "12px" }}
            >
                {info}
            </span>
        </Grid>
    );
};

const RenderArrayInfos = ({ icon, infos, iconStyle, title }) => {
    if (infos) {
        return (
            <Grid item xs={3} sm={3} md={3}>
                <Typography variant="body2" color="text.secondary">
                    <FontAwesomeIcon
                        className="me-1"
                        icon={icon}
                        style={{ ...iconStyle }}
                    />
                    {title}
                </Typography>
                {infos.map((value, index) => {
                    return (
                        <span
                            class="badge bg-glow-dark-blue-color m-1"
                            style={{ fontSize: "12px" }}
                        >
                            {parse(value.description)}
                        </span>
                    );
                })}
            </Grid>
        );
    }
};

const InternalJobDetails = ({ data, modal }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const jobApi = new JobApi();

    const [job, setJob] = useState(data);
    const [showDescription, setShowDescription] = useState(true);
    const [showForm, setShowForm] = useState(false);

    const handleInternalJobApply = async (formData, files) => {

        let data = {
            demandenum: job.demandenum,
            hasForm: job.hasForm,
            title: job.title
        }

        if(job.hasForm){
            data.formData = formData;
            data.files = files;
            data.formId = job.formId;
        }

        let response = await jobApi.applyInternalJobs(data);
        if (response.success) {
            toast.success(t(response.message));
        } else {
            toast.error(t(response.message));
        }
    };

    const handleJobForm = () => {
        setShowForm((prevCheck) => !prevCheck);
        setShowDescription((prevCheck) => !prevCheck);
    };

    useEffect(() => {
        setJob(data);
    }, []);

    if (job) {
        return (
            <>
                {showDescription && (
                    <>
                        <Grid container>
                            <Grid item container>
                                <Grid item xs={12} m={2}>
                                    <Typography
                                        gutterBottom
                                        variant="h4"
                                        component="div"
                                        sx={{ textAlign: "center" }}
                                    >
                                        {job.title}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider sx={{ borderColor: "#bdbdbd" }} />
                        <Grid container>
                            <Grid item container m={2}>
                                <Grid container m={1} spacing={3}>
                                    <Grid
                                        container
                                        item
                                        md={3}
                                        direction="column"
                                    >
                                        <RenderInfo
                                            icon={faCalendarDay}
                                            title={t("datedepublication")}
                                            info={job.date}
                                        />
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        md={3}
                                        direction="column"
                                    >
                                        <RenderInfo
                                            icon={faUserTie}
                                            title={t("client")}
                                            info={job.client}
                                        />
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        md={3}
                                        direction="column"
                                    >
                                        <RenderInfo
                                            icon={faSuitcase}
                                            title={t("entreprise")}
                                            info={job.company}
                                        />
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        md={3}
                                        direction="column"
                                    >
                                        <RenderInfo
                                            icon={faMapMarkerAlt}
                                            title={t("adresse")}
                                            info={job.address}
                                        />
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        md={3}
                                        direction="column"
                                    >
                                        <RenderArrayInfos
                                            icon={faClock}
                                            title={parse(t("typedemplois"))}
                                            infos={job.jobTypes}
                                        />
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        md={3}
                                        direction="column"
                                    >
                                        <RenderArrayInfos
                                            icon={faMagnifyingGlass}
                                            title={t("secteur")}
                                            infos={job.sectors}
                                        />
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        md={3}
                                        direction="column"
                                    >
                                        <RenderArrayInfos
                                            icon={faGlobe}
                                            title={t("territoires")}
                                            infos={job.territories}
                                        />
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        md={3}
                                        direction="column"
                                    >
                                        <RenderArrayInfos
                                            icon={faLanguage}
                                            title={t("langues")}
                                            infos={job.languages}
                                        />
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        md={3}
                                        direction="column"
                                    >
                                        <RenderArrayInfos
                                            icon={faGraduationCap}
                                            title={t("diplomes")}
                                            infos={job.diplomas}
                                        />
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        md={3}
                                        direction="column"
                                    >
                                        <RenderArrayInfos
                                            icon={faStar}
                                            title={t("competences")}
                                            infos={job.skills}
                                        />
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        md={3}
                                        direction="column"
                                    >
                                        <RenderArrayInfos
                                            icon={faIdCard}
                                            title={t("permis")}
                                            info={job.permits}
                                        />
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        md={3}
                                        direction="column"
                                    >
                                        <RenderArrayInfos
                                            icon={faPuzzlePiece}
                                            title={t("benefices")}
                                            info={job.benefits}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider sx={{ borderColor: "#bdbdbd", marginBottom: 5 }} />
                        <Grid m={4}>
                            {
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurity.sanitize(
                                            job.description
                                        ),
                                    }}
                                />
                            }
                        </Grid>
                    </>
                )}

                {showForm && job.hasForm && (
                    <>
                        <Grid m={2}>
                            <FormGenerator
                                formData={job.formData}
                                readOnlyForm={false}
                                formSubmit={handleInternalJobApply}
                                formId={"internalJobApplyForm"}
                                t={t}
                            />
                        </Grid>
                    </>
                )}

                <Divider sx={{ borderColor: "#bdbdbd", marginTop: 5 }} />
                <Grid container sx={{ justifyContent: "center" }}>
                    <Box
                        sx={{
                            textAlign: "center",
                            justifyContent: "center",
                        }}
                        m={2}
                    >
                        <button
                            type="button"
                            onClick={modal.hide}
                            className="btn btn-secondary m-1"
                        >
                            {t("fermer")}
                        </button>

                        {job.hasForm && (
                            <button
                                type="button"
                                onClick={() => {
                                    handleJobForm();
                                }}
                                class={
                                    showForm
                                        ? "btn btn-warning m-1"
                                        : "btn btn-success m-1"
                                }
                            >
                                {showForm ? t("precedent") : t("suivant")}
                            </button>
                        )}

                        {!job.hasForm && (
                            <button
                                type="button"
                                onClick={() => {
                                    handleInternalJobApply();
                                }}
                                class="btn btn-success m-1"
                            >
                                {t("postuler")}
                            </button>
                        )}

                        {showForm && (
                            <button
                                type="button"
                                type="submit" form="internalJobApplyForm"
                                class="btn btn-success m-1"
                            >
                                {t("postuler")}
                            </button>
                        )}
                    </Box>
                </Grid>
            </>
        );
    }
};

export default InternalJobDetails;
