import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import moment from "moment";
import { useDropzone } from "react-dropzone";
import CandidateApi from "../../services/Api/CandidateApi";
import RowActions from "./DocumentsListActions";
import Select from "react-select";
import "./DocumentsList.scss";
import { acceptedUploadFileTypes } from "../../helpers/acceptedUploadFileTypes";
import DocumentApi from "../../services/Api/DocumentApi";
import { useTranslation } from "react-i18next";
import { Loader } from "../../components";
import { useStore } from "react-redux";
import { EDIT_DOCUMENTS } from "../../helpers/accessSubmodule";

const DocumentsList = () => {
    const { t } = useTranslation();
    const candidateApi = new CandidateApi();
    const documentApi = new DocumentApi();

    const store = useStore();
    const { submodules } = store.getState().auth.access;

    const [loading, setLoading] = useState(true);
    const [documents, setDocuments] = useState([]);
    const [documentsTypes, setDocumentsTypes] = useState();
    const [currentDocumentType, setCurrentDocumentType] = useState();
    const [dropzoneDisabled, setDropzoneDisabled] = useState("disabled");
    const componentIsMounted = useRef(true);
    let toasts = [];

    const getDocuments = async (documentTypeId) => {
        const response = await candidateApi.getDocuments(documentTypeId);
        if (response.success) {
            setDocuments(response.documents);
            setDocumentsTypes(response.documentsTypes);
            setLoading(false);
        } else {
            toast.error(t(response.message));
        }
    };

    const columns = [
        {
            name: t("type"),
            sortable: true,
            selector: "type",
        },
        {
            name: t("nom"),
            sortable: true,
            wrap: true,
            selector: "name",
        },
        {
            name: t("date"),
            sortable: true,
            selector: "date",
        },
        {
            name: t("actions"),
            selector: "actions",
            sortable: false,
            show: false,
            cell: (row) => <RowActions row={row} />,
        },
    ];

    const dataTableConverter = (data) => {
        let dataTable = [];
        if (data) {
            data.map((value, index) => {
                let timestamp = new Date().getUTCMilliseconds();
                dataTable.push({
                    key: index + "_" + timestamp,
                    index: index,
                    type: value.type,
                    name: value.name,
                    date: value.date ? moment(value.date).format("L") : "",
                    shared: value.shared,
                    acions: null,
                    url: value.url,
                    urlViewer: value.urlViewer,
                    downloadUrl: value.downloadUrl,
                    extension: value.extension,
                    sign: value.sign,
                });
            });
        }
        return dataTable;
    };

    const documentsTypeToSelect = (data) => {
        let outputData = [];
        if (data) {
            data.map((value, index) => {
                outputData.push({
                    value: value.id,
                    label: t(value.name),
                });
            });
        }
        return outputData;
    };

    const handleDocumentTypeChange = (selectedOption) => {
        if (selectedOption) {
            getDocuments(selectedOption.value);
            setCurrentDocumentType(selectedOption.value);
        } else {
            getDocuments();
            setCurrentDocumentType(null);
        }
        setDropzoneDisabled(selectedOption === null ? "disabled" : "");
    };

    const handleUploadDocument = (progressEvent, searchedName) => {
        let progress = 1;
        const totalLength = progressEvent.lengthComputable
            ? progressEvent.total
            : progressEvent.target.getResponseHeader("content-length") ||
              progressEvent.target.getResponseHeader(
                  "x-decompressed-content-length"
              );
        if (totalLength !== null) {
            progress = Math.round((progressEvent.loaded * 100) / totalLength);
        }

        if (toasts) {
            const result = toasts.find(({ name }) => name === searchedName);
            if (result) {
                toast.update(result.id, {
                    progress: progress / 100,
                });
            }
        }
    };

    const createNewToast = (filesName) => {
        if (filesName) {
            let uploadToastUpdate = [];
            filesName.map((value) => {
                const toastId = toast.info(value, {
                    progress: 0,
                });
                uploadToastUpdate.push({ name: value, id: toastId });
            });
            toasts = uploadToastUpdate;
        }
    };

    const handleDrop = (acceptedFiles) => {
        if (currentDocumentType) {
            let filesName = [];

            acceptedFiles.forEach((file) => {
                // Check the type of the file
                if (acceptedUploadFileTypes.includes(file.type)) {
                    // Check the size of the file
                    if (file.size > 0) {
                        filesName.push(file.name);

                        const reader = new FileReader();

                        reader.readAsDataURL(file);

                        reader.onabort = () => {
                            toast.error("aborted");
                        };

                        reader.onerror = () => {
                            toast.error("failed");
                        };

                        reader.onload = () => {
                            const newDocument = {
                                type: currentDocumentType,
                                name: file.name,
                                data:
                                    "data:" +
                                    file.type +
                                    ";base64," +
                                    reader.result.toString(),
                                date: null,
                                shared: false,
                            };

                            documentApi
                                .uploadFile(
                                    [newDocument],
                                    currentDocumentType,
                                    handleUploadDocument
                                )
                                .then((data) => {
                                    if (componentIsMounted.current) {
                                        setDocuments(data.documents);
                                    }
                                    toast.success(
                                        file.name + " " + t("ajoute")
                                    );
                                })
                                .catch((error) => {
                                    toast.error(
                                        file.name + " " + error.message
                                    );
                                    return null;
                                });
                        };
                    } else {
                        toast.error(
                            t("latailledufichiernestpascompatible") +
                                " : " +
                                file.name
                        );
                    }
                } else {
                    toast.error(
                        t("letypedefichiernestpascompatible") +
                            " : " +
                            file.name
                    );
                }
            });
            createNewToast(filesName);
        } else {
            toast.error(t("vousdevezchoisiruntypededocument"));
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: handleDrop,
        disabled: dropzoneDisabled,
    });

    useEffect(() => {
        getDocuments();
    }, []);

    if (documents && !loading) {
        return (
            <>
                {submodules.includes(EDIT_DOCUMENTS) && (
                    <div
                        {...getRootProps({
                            className: `dropzone mb-3 ${dropzoneDisabled}`,
                        })}
                    >
                        <input {...getInputProps()} />
                        <p>{t("cliqueroudeposerlesfichiersici")}</p>
                    </div>
                )}
                <div className="mb-3">
                    <label className="mb-3">{t("typededocument")}</label>
                    <Select
                        isClearable={true}
                        isSearchable={true}
                        options={documentsTypeToSelect(documentsTypes)}
                        onChange={handleDocumentTypeChange}
                        placeholder={t("choixdutypededocument")}
                        menuPosition={'fixed'} 
                        />
                </div>
                <DataTable
                    columns={columns}
                    noDataComponent={t("aucundocumenttrouve")}
                    data={dataTableConverter(documents)}
                    keyField="key"
                    pagination
                    noHeader
                    highlightOnHover
                    paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                    paginationComponentOptions={{
                        rowsPerPageText: t("lignesparpage"),
                        rangeSeparatorText: t("sur"),
                        noRowsPerPage: false,
                        selectAllRowsItem: false,
                        selectAllRowsItemText: "All",
                    }}
                />
            </>
        );
    } else {
        return <Loader />;
    }
};

export default DocumentsList;
