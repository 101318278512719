import jwt_decode from "jwt-decode";

const TokenService = () => {

    const decode = (token) => {
        if(token) {
            return jwt_decode(token);
        }
        return false;
    }

    const isExpired = (token) => {
        var tokenDecoded = decode(token);
        if (!tokenDecoded.exp) {
            return false;
        }
        return Date.now() > tokenDecoded.exp * 1000;
    }

    const getCandidateId = (token) => {
        var tokenDecoded = decode(token);
        return tokenDecoded.candidate_id;
    }

    const getEmployerId = (token) => {
        var tokenDecoded = decode(token);
        return tokenDecoded.employee_id;
    }

    const getCieId = (token) => {
        var tokenDecoded = decode(token);
        return tokenDecoded.cie_id;
    }

    return {
        decode,
        isExpired,
        getCandidateId,
        getEmployerId,
        getCieId,
    }
}

export default TokenService();