import {configureStore} from "@reduxjs/toolkit";
import { setupListeners } from '@reduxjs/toolkit/query'
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { combineReducers } from '@reduxjs/toolkit'
import {
    persistReducer,
    persistStore,
} from 'redux-persist'

import {AuthSlice, MenuSlice, HeaderSlice} from "./slices";

const persistConfig = {
    key: "root",
    storage,
};

const rootReducer = combineReducers({
    auth: AuthSlice,
    menu: MenuSlice,
    header: HeaderSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
});

setupListeners(store.dispatch)

export const persistor = persistStore(store);
