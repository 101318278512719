import React, {useEffect, useState} from 'react';

import TextField from "./Field/TextField";
import TextareaField from "./Field/TextareaField";
import DateField from "./Field/DateField";
import SelectField from "./Field/SelectField";
import FileField from "./Field/FileField";
import "./form.scss";
import {getBase64} from "helpers/files";
import { CircularProgress } from '@mui/material';

const FormGenerator = ({formData, readOnlyForm, formSubmit, formId, t}) => {

    /**
     * States
     */
    const [loading, setLoading] = useState(true);
    const [currentData, setCurrentData] = useState([formData]);
    const [files, setFiles] = useState([]);

    /**
     * Handle field change
     */
    const handleChange = (index, event) => {
        const dataUpdate = currentData;
        dataUpdate[index].field.value = event.currentTarget.value;
        setCurrentData(dataUpdate);
    };

    /**
     * Handle select change
     */
    const handleSelectChange = (index, event) => {
        const dataUpdate = currentData;
        dataUpdate[index].field.selected = event.value;
        setCurrentData(dataUpdate);
    }

    /**
     * Handle file change
     */
    const handleFileChange = (index, event) => {
        if (event.currentTarget.type === "file") {
            let files = [];
            for (let i = 0; i < event.currentTarget.files.length; i++) {
                files[i] = {name: event.currentTarget.files[i].name, content: ""};
                getBase64(event.currentTarget.files[i], (result) => {
                    files[i].content = result;
                });
            }
            setFiles(files);
        }
    }

    /**
     * Handle submit form
     */
    const handleSubmit = event => {
        event.preventDefault();
        if (!readOnlyForm && window.confirm(t("etesvoussurdevouloirenregistrerleformulaire"))) {

            /**
             * Loop in the current data to extract only the field array
             */
            let data = [];
            currentData.map((value) => {
                data.push(value.field);
            })

            formSubmit(data, files);
            handleClear();
        }
    }

    const handleClear = () => {

    }

    /**
     * Mount data
     */
    useEffect(() => {
        setLoading(true);
        const timer = setTimeout(() => {
            setCurrentData(formData);
            setLoading(false);
        }, 300);
        return () => clearTimeout(timer);
    }, [formData])

    /**
     * Check if the data exist
     */
    if (currentData) {

        return (
            <>
                {loading && <div className="d-flex justify-content-center"><CircularProgress/></div>}
                {!loading &&
                <form onSubmit={handleSubmit} id={formId}>
                        {currentData.map((item, index) => {

                            if (item.component && item.field) {

                                switch (item.component) {
                                    case "text":
                                        return (
                                            <TextField
                                                key={item.field.id}
                                                index={index}
                                                field={item.field}
                                                handleChange={handleChange}
                                                readOnlyForm={readOnlyForm}
                                            />
                                        );
                                    case "textarea":
                                        return (
                                            <TextareaField
                                                key={item.field.id}
                                                index={index}
                                                field={item.field}
                                                handleChange={handleChange}
                                                readOnlyForm={readOnlyForm}
                                            />
                                        );
                                    case "date":
                                        return (
                                            <DateField
                                                key={item.field.id}
                                                index={index}
                                                field={item.field}
                                                handleChange={handleChange}
                                                readOnlyForm={readOnlyForm}
                                            />
                                        );
                                    case "select":
                                        return (
                                            <SelectField
                                                key={item.field.id}
                                                index={index}
                                                field={item.field}
                                                handleSelectChange={handleSelectChange}
                                                readOnlyForm={readOnlyForm}
                                            />
                                        );
                                    case "file":
                                        return (
                                            <FileField
                                                key={item.field.id}
                                                index={index}
                                                field={item.field}
                                                handleFileChange={handleFileChange}
                                                readOnlyForm={readOnlyForm}
                                                t={t}
                                            />
                                        );
                                }
                            }
                        })}
                </form>
                }
            </>
        );

    }
};

export default FormGenerator;