import Axios from "./AxioConfig";

class JobApi {

    getInternalJobs() {
        return Axios.get('/api/internal-job').then((response => {
            return response.data;
        }));
    }

    applyInternalJobs(data) {
        return Axios.post('/api/internal-job/apply', data).then((response => {
            return response.data;
        }));
    }
}

export default JobApi