import React, { useState } from "react";
import EmployeeApi from "../../services/Api/EmployeeApi";
import { toast } from "react-toastify";
import AuthApi from "../../services/Api/AuthApi";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, Link, useNavigate } from "react-router-dom";
import {
    Button,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    Typography,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import { useTheme } from "@mui/material/styles";

const ForgotPassword = () => {
    const employeeApi = new EmployeeApi();
    const authApi = new AuthApi();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();

    const [cieData, setCieData] = useState([]);
    const [showForgotPassword, setShowForgotPassword] = useState(true);
    const [showCieChoice, setShowCieChoice] = useState(false);
    const [selectedCie, setSelectedCie] = useState("");
    const [email, setEmail] = useState("");

    const handleEmailChange = (e, setFieldValue) => {
        setFieldValue("email", e.target.value);
        setEmail(e.target.value);
    };

    const handleSelectedCieChange = (e, setFieldValue) => {
        setFieldValue("cie", e.target.value);
        setSelectedCie(e.target.value);
    };

    const handleForgotPassword = async () => {
        const data = { email: email };
        const response = await authApi.forgotPassword(data);
        if (response.success) {
            toast.success(t(response.message));
            setTimeout(() => {
                navigate("/");
            }, 1500);
        } else {
            toast.error(t(response.message));
        }
    };

    const handleForgotPasswordWithCie = async () => {
        if (selectedCie) {
            const data = { email: email, cie: selectedCie };
            const response = await authApi.forgotPassword(data);
            if (response.success) {
                toast.success(t(response.message));
                setTimeout(() => {
                    navigate("/");
                }, 1500);
            } else {
                toast.error(t(response.message));
            }
        } else {
            toast.error(t("veuillezselectionerunecompagnie"));
        }
    };

    const checkEmployeeCie = async () => {
        const response = await employeeApi.getEmployeeCie(email);
        if (response.success) {
            if (response.total > 1) {
                setShowForgotPassword(false);
                setShowCieChoice(true);
                setCieData(response.cieData);
            } else {
                handleForgotPassword();
            }
        } else {
            toast.error(t(response.message));
        }
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="baseline"
                    sx={{ mb: { xs: -0.5, sm: 0.5 } }}
                >
                    <Typography variant="h3" align="center">
                        {t("motdepasseoublie")}
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                {showForgotPassword && (
                    <Formik
                        initialValues={{
                            email: "",
                        }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string()
                                .email(t("entreruncourrielvalide"))
                                .max(255)
                                .required(t("courrielobligatoire")),
                        })}
                        onSubmit={(values, { setSubmitting }) => {
                            checkEmployeeCie();
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            setFieldValue,
                        }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="email-login">
                                                {t("courriel")}
                                            </InputLabel>
                                            <OutlinedInput
                                                id="email-login"
                                                type="email"
                                                value={values.email}
                                                name="email"
                                                onBlur={handleBlur}
                                                onChange={(e) =>
                                                    handleEmailChange(
                                                        e,
                                                        setFieldValue
                                                    )
                                                }
                                                fullWidth
                                                error={Boolean(
                                                    touched.email &&
                                                        errors.email
                                                )}
                                            />
                                            {touched.email && errors.email && (
                                                <FormHelperText
                                                    error
                                                    id="standard-weight-helper-text-email-login"
                                                >
                                                    {errors.email}
                                                </FormHelperText>
                                            )}
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sx={{ mt: -1 }}>
                                        <Stack
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <Link
                                                variant="h6"
                                                component={RouterLink}
                                                to="/"
                                                style={{
                                                    textDecoration: "none",
                                                    color: "#009ef7",
                                                    fontWeight: "600",
                                                    fontSize: "15px",
                                                }}
                                                color="text.primary"
                                            >
                                                {t("ouvrirunesession")}
                                            </Link>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            disableElevation
                                            // disabled={isSubmitting}
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            sx={{
                                                backgroundColor:
                                                    theme.palette.glow.primary,
                                            }}
                                        >
                                            {t("envoyer")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                )}

                {showCieChoice && (
                    <Formik
                        initialValues={{
                            cie: "",
                        }}
                        validationSchema={Yup.object().shape({
                            cie: Yup.string().required(
                                t("veuillezselectionerunecompagnie")
                            ),
                        })}
                        onSubmit={(values) => {
                            handleForgotPasswordWithCie();
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            setFieldValue,
                        }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <Grid item xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Stack spacing={1}>
                                                <InputLabel>
                                                    {t("choixdelacompagnie")}
                                                </InputLabel>
                                                <Select
                                                    id="cie-login"
                                                    name="cie"
                                                    fullWidth
                                                    error={Boolean(
                                                        touched.cie &&
                                                            errors.cie
                                                    )}
                                                    onBlur={handleBlur}
                                                    value={values.cie}
                                                    onChange={(e) =>
                                                        handleSelectedCieChange(
                                                            e,
                                                            setFieldValue
                                                        )
                                                    }
                                                >
                                                    {cieData.map(
                                                        (
                                                            { cieSeq, cieName },
                                                            index
                                                        ) => (
                                                            <MenuItem
                                                                key={index}
                                                                value={cieSeq}
                                                            >
                                                                {cieName}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                                {touched.cie && errors.cie && (
                                                    <FormHelperText
                                                        error
                                                        id="standard-weight-helper-text-cie-login"
                                                    >
                                                        {errors.cie}
                                                    </FormHelperText>
                                                )}
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                disableElevation
                                                fullWidth
                                                // disabled={isSubmitting}
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                            >
                                                {t("valider")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                )}
            </Grid>
        </Grid>
    );
};

export default ForgotPassword;
