
class FilterHelper {

    // Filter the list of data with the search input
    filteredItems = (list, filterText) => {
        const columns = list[0] && Object.keys(list[0]);
        return list.filter((row) =>
            columns.some(
                (column) => typeof row[column] === "string" && row[column].toLowerCase().indexOf(filterText.toLowerCase()) > -1
            )
        );
    }

}

export default FilterHelper;