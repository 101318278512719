import "react-toastify/dist/ReactToastify.css";
import ThemeCustomization from "./themes";
import { toast, ToastContainer } from "react-toastify";
import Routes from "./routes";
import { useDispatch, useSelector } from "react-redux";
import TokenService from "./services/TokenService";
import { clearAuth } from "./redux/slices/AuthSlice";
import { useEffect } from "react";

import "./App.scss";

const App = () => {
    const auth = useSelector((state) => state.auth);
    const { token } = auth;
    const isAuth = Boolean(token);

    const dispatch = useDispatch();

    const checkToken = async () => {
        if (token !== null) {
            if (TokenService.isExpired(token)) {
                dispatch(clearAuth());
            }
        }
    };

    useEffect(() => {
        if (isAuth) {
            checkToken();
        }
    }, []);

    return (
        <>
            <ThemeCustomization>
                <Routes />
                <ToastContainer
                    position={toast.POSITION.TOP_RIGHT}
                    theme="colored"
                    autoClose={3000}
                    pauseOnHover={false}
                />
            </ThemeCustomization>
        </>
    );
};
export default App;
