import React from "react";
import { useTranslation } from "react-i18next";
import {
    Grid,
    Typography,
    Stack,
    Box,
    Paper,
    Avatar,
    IconButton,
} from "@mui/material";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAt,
    faHome,
    faMapMarkedAlt,
    faMobileAndroid,
    faPen,
} from "@fortawesome/pro-solid-svg-icons";
import { EDIT_PROFILE, SHOW_ADDRESS, SHOW_PHONES } from "../../../helpers/accessSubmodule";
import ProfileFormModal from "./ProfilForm/ProfileFormModal";
import NiceModal from "@ebay/nice-modal-react";
import parse from "html-react-parser";

const RenderInfo = ({ icon, info, iconStyle }) => {
    return (
        <Grid item xs={3} sm={3} md={3}>
            <Typography variant="body1" color="text.secondary">
                <Stack direction="row" alignItems="center" gap={1}>
                    <FontAwesomeIcon
                        className="me-1"
                        icon={icon}
                        style={{ ...iconStyle }}
                    />
                    {info}
                </Stack>
            </Typography>
        </Grid>
    );
};

const ProfileDetails = () => {
    const { t } = useTranslation();
    const { user, access } = useSelector((state) => state.auth);
    const { module, submodules } = access;

    if (user) {
        return (
            <Paper
                sx={{
                    p: 3,
                    margin: "auto",
                    flexGrow: 1,
                    borderRadius: 2,
                    backgroundColor: "#fff",
                    height: "500px",
                }}
            >
                <Grid container spacing={2}>
                    {submodules.includes(EDIT_PROFILE) && (
                        <Grid
                            item
                            container
                            sx={{ justifyContent: "flex-end" }}
                        >
                            <IconButton
                                color="primary"
                                aria-label="edit profile"
                                component="label"
                                size="small"
                                onClick={() => NiceModal.show(ProfileFormModal)}
                            >
                                <FontAwesomeIcon icon={faPen} size="1x" />
                            </IconButton>
                        </Grid>
                    )}
                    <Grid container sx={{ justifyContent: "center" }}>
                        <Box
                            sx={{
                                boxShadow: 0,
                                borderRadius: 2,
                                textAlign: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Avatar
                                alt="profile user"
                                src={user.avatar}
                                sx={{
                                    maxWidth: "120px",
                                    height: "120px",
                                    width: "100%",
                                    p: 2,
                                }}
                                variant="rounded"
                            />
                        </Box>
                    </Grid>
                    <Grid item container>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Typography
                                    gutterBottom
                                    variant="h4"
                                    component="div"
                                    sx={{ textAlign: "center" }}
                                >
                                    {user.firstName + " " + user.lastName}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={2} columns={4}>
                                    <RenderInfo icon={faAt} info={user.email} />
                                    {submodules.includes(SHOW_PHONES) && user.phones.home && (
                                        <RenderInfo
                                            icon={faHome}
                                            info={user.phones.home}
                                        />
                                    )}
                                    {submodules.includes(SHOW_PHONES) && user.phones.cell && (
                                        <RenderInfo
                                            icon={faMobileAndroid}
                                            info={user.phones.cell}
                                            iconStyle={{ marginLeft: "3px" }}
                                        />
                                    )}
                                    {submodules.includes(SHOW_ADDRESS) && user.address.name && (
                                        <RenderInfo
                                            icon={faMapMarkedAlt}
                                            info={parse(user.address.name)}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        );
    }
};

export default ProfileDetails;
